/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, TextField, Tooltip } from "@mui/material";
import { debounce } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  bookmarkAiMessage,
  bookmarkQuestions,
  deleteConversations,
  // getBookmarkedMessages,
  getChatHistory,
  getInitialChildSavedStatus,
  getSavedQuestions,
  saveAiChildRecommendations,
  shareRecommendations,
} from "../../../apis/recommendation/recommendation";
import Button from "../../../components/button";
import SioIcon from "../../../components/icons/IconLibrary";
import { DataContext } from "../../../components/layout/layout";
import MarkdownComponent from "../../../components/MarkdownComponent";
import PDFGenerator from "../../../components/pdf/buttons/DownloadPdf";
import SioTooltipClampedText from "../../../components/TooltipClampedText";
import { getUserNames } from "../../../utils";
import { checkAndRefreshToken } from "../../../utils/authUtils";
import SioLoader from "../../../components/loader";

const SuggestionsContainer = ({
  recommendationData,
  fileUUID,
  isDataLoading,
  setFilteredSuggestions,
}) => {
  const divRef = useRef(null);
  const [scrollToTop, setScrollToTop] = useState(false);
  const [page, setPage] = useState(1);

  const lastPage = useRef(null);
  const [userId, setUserId] = useState(null);
  const [openRemoveModal, setRemoveModalOpen] = useState(false);
  const [isRenderingDownLoadButton, setIsRenderingDownLoadButton] =
    useState(false);

  const handleRemoveModalClose = () => {
    setRemoveModalOpen(false);
  };

  const handleRemoveModalClickOpen = (item) => {
    setRemoveModalOpen(true);
  };

  const navigate = useNavigate();

  const {
    APIData,
    setPromptData,
    fileUploadRequest,
    setIsLoading,
    recData,
    setRecData,
    updatedSessionId,
    currentFileName,
    setCurrentFileName,
    messageId,
    setMessageId,
    aiChildRecommendations,
    setAiChildRecommendations,
    recId,
    loadingPromptResponse,
    savedAiChild,
    setSavedAiChild,
    setFilteredRec,
    suggestionsRefresh,
  } = useContext(DataContext);

  const fullUrl = window.location.href;
  const modifiedURL = fullUrl.split("/").slice(0, 3).join("/");
  const [prompt, setPrompt] = useState("");
  const [actualSavedStatus, setActualSavedStatus] = useState(null);

  const handleShare = async () => {
    const { shareable_url } = await shareRecommendations(
      recId.current,
      modifiedURL
    );
    const encodedShareUrl = encodeURIComponent(shareable_url);
    const emailShareUrl = `mailto:?subject=Check out this data!&body=Check out this link: ${encodedShareUrl}`;
    window.location.href = emailShareUrl;
  };

  const computeDateTime = (updated) => {
    let hours = updated.getHours();
    let month = updated.toLocaleString("default", { month: "short" });
    let date = updated.getDate();
    let year = updated.getFullYear();

    const minutes = updated.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    const time = `${date} ${month} ${year}, ${hours}:${formattedMinutes} ${ampm}`;
    return time;
  };

  const calculateTime = (date) => {
    if (!date) return null;
    const today = new Date();
    const updated = new Date(date);
    const updatedMonth = updated.toLocaleString("default", { month: "short" });
    const updatedDate = updated.getDate();
    const todayMonth = today.toLocaleString("default", { month: "short" });
    const todayDate = today.getDate();

    let recommendationDate;
    if (todayMonth === updatedMonth) {
      if (todayDate - updatedDate >= 1) {
        recommendationDate = `${computeDateTime(updated)}`;
      } else if (todayDate - updatedDate === 0) {
        recommendationDate = computeDateTime(updated);
      }
    } else {
      recommendationDate = computeDateTime(updated);
    }
    return recommendationDate;
  };

  const fetchChatHistory = async (
    page = 1,
    perPage = 10,
    previousScrollHeight,
    previousScrollTop
  ) => {
    const token = await checkAndRefreshToken();
    if (!token) navigate("/login");
    // const { bookmarked_messages } = await getBookmarkedMessages();

    const nextQueryParams = new URLSearchParams();
    nextQueryParams.append("recommendation_id", recId.current);
    nextQueryParams.append("page", page);
    nextQueryParams.append("per_page", perPage);

    let historyData;
    if (!userId) {
      const { data } = await getUserNames();
      nextQueryParams.append("user_id", data?.user?.id);
    } else {
      nextQueryParams.append("user_id", userId);
    }

    try {
      const { history, total_pages } = await getChatHistory(
        nextQueryParams,
        token
      );
      lastPage.current = total_pages;
      if (history && history.length > 0) {
        updatedSessionId.current = history[0].session_id;
        let pushData = [];
        history.forEach((value) => {
          pushData.unshift(
            value.type === "ai"
              ? {
                  response: value?.content,
                  id: value?.id,
                  likes: value?.likes,
                  disLikes: value?.dislikes,
                  created_at: value?.created_at,
                  qualitative_saved: value?.qualitative_bookmarked,
                  quantitative_saved: value?.quantitative_bookmarked,
                  qualitative_bookmarked: value?.qualitative_saved,
                  quantitative_bookmarked: value?.quantitative_saved,
                  session_id: value?.session_id,
                  rec_id: value?.recommendation_id,
                }
              : {
                  input: {
                    content: value?.content,
                    id: value?.id,
                    isSaved: false,
                  },
                  id: value?.id,
                  likes: value?.likes,
                  disLikes: value?.dislikes,
                  created_at: value?.created_at,
                  saved_status: value?.saved_status,
                  qualitative_saved: value?.qualitative_bookmarked,
                  quantitative_saved: value?.quantitative_bookmarked,
                  session_id: value?.session_id,
                }
          );
        });
        setRecData((prevArray) => [...pushData, ...prevArray]);
        if (
          history &&
          history.length > 0 &&
          history[0].qualitative_question !== null &&
          history[0].quantitative_question !== null
        ) {
          const aiRec =
            history && history.length > 0
              ? [
                  history[0].qualitative_question,
                  history[0].quantitative_question,
                  history[0].qualitative_bookmarked,
                  history[0].quantitative_bookmarked,
                ]
              : [];
          setAiChildRecommendations(aiRec);
          setMessageId(history[0].id);
        }

        setRecData((prevArray) => {
          const filtered = prevArray.filter(
            (items) => items?.LoadingData !== "LoadingOldChat"
          );
          return filtered;
        });

        setIsLoading(false);
        historyData = pushData;
      } else {
        setRecData([
          {
            response: recommendationData?.content,
            created_at: recommendationData?.created_at,
            id: recommendationData?.id,
            session_id: recommendationData?.session_id,
          },
        ]);
        setIsLoading(false);
        updatedSessionId.current = null;
        historyData = [
          {
            response: recommendationData?.content,
            created_at: recommendationData?.created_at,
            id: recommendationData?.id,
            session_id: recommendationData?.session_id,
          },
        ];
      }
      setIsLoading(false);
      if (
        (previousScrollHeight && previousScrollHeight !== 0) ||
        (previousScrollTop && previousScrollTop !== 0)
      ) {
        setTimeout(() => {
          const container = document.getElementById("container");
          const newScrollHeight = container.scrollHeight;
          const scrollHeight = divRef.current.scrollHeight;
          const topTenPercent = scrollHeight * 0.03;
          container.scrollTop =
            previousScrollTop +
            (newScrollHeight - previousScrollHeight) -
            topTenPercent;
        }, 0);
      }
      return [historyData, total_pages];
    } catch (err) {
      setIsLoading(false);
      toast.error("Error:", err, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
    }
    setIsLoading(false);
  };

  const perPage = 10;

  const handleScroll = useCallback(
    debounce((e) => {
      if (!divRef?.current) return;
      const currentScrollTop = divRef.current.scrollTop;
      const scrollHeight = divRef.current.scrollHeight;
      const topTenPercent = scrollHeight * 0.1;

      const container = document.getElementById("container");
      const previousScrollHeight = container.scrollHeight;
      const previousScrollTop = container.scrollTop;
      if (currentScrollTop <= topTenPercent) {
        setScrollToTop(true);
        setPage((prevPage) => {
          const updatedPage = prevPage + 1;
          if (updatedPage <= lastPage.current) {
            setRecData((prevArray) => {
              const updatedArray = [
                { LoadingData: "LoadingOldChat" },
                ...prevArray,
              ];
              return updatedArray;
            });

            fetchChatHistory(
              updatedPage,
              perPage,
              previousScrollHeight,
              previousScrollTop
            );
          }

          return updatedPage;
        });
      }
    }, 300),
    [page]
  );

  const inputRef = useRef(null);

  const getLatestHistory = async () => {
    if (recId.current) {
      const { data } = await getUserNames();
      setUserId(data?.user?.id);
      setScrollToTop(false);
      const [historyData] = await fetchChatHistory();
      const lastChatHistoryPrompt = historyData[historyData.length - 1];

      if (historyData.length === 1) {
        const {
          qualitative_bookmarked,
          quantitative_bookmarked,
          qualitative_saved,
          quantitative_saved,
        } = await getInitialChildSavedStatus(recId.current);

        setActualSavedStatus({
          qualitative_saved: qualitative_saved,
          quantitative_saved: quantitative_saved,
        });

        setSavedAiChild({
          qualitative_saved: qualitative_bookmarked,
          quantitative_saved: quantitative_bookmarked,
        });
      } else {
        setActualSavedStatus({
          qualitative_saved: lastChatHistoryPrompt?.qualitative_bookmarked,
          quantitative_saved: lastChatHistoryPrompt?.quantitative_bookmarked,
        });

        setSavedAiChild({
          qualitative_saved: lastChatHistoryPrompt?.qualitative_saved,
          quantitative_saved: lastChatHistoryPrompt?.quantitative_saved,
        });
      }
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      setRecData([]);
      recId.current = recommendationData?.id;
      if (!recId.current) return;

      await getLatestHistory();
      setPage(1);
      setPrompt("");

      const activeAPIData = APIData.find(
        (data) =>
          data.payload.apiSessionId === updatedSessionId.current && data.status
      );

      if (activeAPIData) {
        setRecData((prevArray) => {
          const updatedArray = [
            ...(activeAPIData.apiCallname === "Edit"
              ? prevArray.slice(0, activeAPIData.questionIndex)
              : prevArray),
            {
              input: {
                content: activeAPIData.payload.apiPrompt,
                id: null,
                isSaved: false,
                session_id: updatedSessionId.current,
              },
            },
            { response: "Loading..." },
          ];
          return updatedArray;
        });
      } else if (
        suggestionsRefresh.find(
          (data) =>
            data.payload.apiSessionId === updatedSessionId.current &&
            data.status
        )
      ) {
        const activeAPIData = suggestionsRefresh.find(
          (data) =>
            data.payload.apiSessionId === updatedSessionId.current &&
            data.status
        );

        setRecData((prevArray) => {
          const updatedArray = [
            ...prevArray.slice(0, activeAPIData.index),

            { response: "Loading..." },
          ];
          return updatedArray;
        });
      }
    };

    initializeData();
  }, [recommendationData]);

  useEffect(() => {
    const scrollToResponse = () => {
      if (divRef.current && !scrollToTop) {
        const container = divRef.current;
        const lastDiv = container.lastElementChild.lastElementChild;
        if (lastDiv) {
          lastDiv.scrollIntoView({ behavior: "smooth", block: "start" });
          const scrollPosition =
            lastDiv.offsetTop +
            lastDiv.offsetHeight -
            lastDiv.getBoundingClientRect().height -
            container.offsetHeight;

          container.scrollTo({
            top: scrollPosition + 90,
            behavior: "smooth",
          });
        }
      }
    };
    scrollToResponse();
  }, [recData]);

  useEffect(() => {
    if (recommendationData) {
      if (recommendationData.follow_up_questions) {
        setAiChildRecommendations([
          recommendationData.follow_up_questions.qualitative_question,
          recommendationData.follow_up_questions.quantitative_question,
        ]);
      } else {
        setAiChildRecommendations([
          recommendationData.qualitative_question,
          recommendationData.quantitative_question,
          recommendationData.qualitative_saved,
          recommendationData.quantitative_saved,
        ]);
      }
      setMessageId(recommendationData.id);

      let result;

      if (!Array.isArray(recommendationData?.original_filename)) {
        let cleanedInput = recommendationData?.original_filename
          .replace(/^{|}$/g, "")
          .replace(/^"|"$/g, "");

        result = cleanedInput
          .split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/)
          .map((str) => str.trim().replace(/^"|"$/g, ""));
      } else {
        result = recommendationData?.original_filename;
      }
      setCurrentFileName(result);
    }
  }, [recommendationData]);

  const handleInputChange = (value) => {
    setPrompt(value);
  };

  const handlePaste = (event) => {
    setPrompt("");
    event.preventDefault();

    const clipboardText = event.clipboardData.getData("text");
    const cleanedText = clipboardText
      .split("\n")
      .filter((line) => line.trim() !== "")
      .join("\n")
      .trim();

    const textarea = inputRef.current.querySelector("textarea");

    if (inputRef.current) {
      if (textarea) {
        const { selectionStart, selectionEnd } = textarea;

        const currentValue = prompt || "";

        const newValue =
          currentValue.substring(0, selectionStart) +
          cleanedText +
          currentValue.substring(selectionEnd);

        setPrompt(newValue);

        textarea.value = newValue;

        textarea.dispatchEvent(new Event("input", { bubbles: true }));
      }
    }
  };

  const handlePrompt = (e) => {
    handleInputChange(e.target.value);
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      return;
    }

    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();

      const isApiCallInProgress =
        APIData.some(
          (data) =>
            data.payload.apiSessionId === updatedSessionId.current &&
            data.status
        ).length > 0;

      if (
        isApiCallInProgress ||
        suggestionsRefresh.filter(
          (data) =>
            data?.payload?.apiSessionId === updatedSessionId?.current &&
            data.status
        ).length > 0
      ) {
        return;
      }

      const input = e.target.value.trim();
      if (!input) {
        toast.error("Please provide input", {
          icon: <SioIcon name="close" size={16} color="blue" />,
        });
        return;
      }

      setScrollToTop(false);
      setPrompt("");
      await fileUploadRequest(input);
    }
  };

  const handleFileName = () => {
    if (!Array.isArray(recommendationData?.original_filename)) {
      const cleanedInput = recommendationData?.original_filename
        .replace(/^{|}$/g, "")
        .replace(/^"|"$/g, "");

      return cleanedInput
        .split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/)
        .map((str) => str.trim().replace(/^"|"$/g, ""));
    } else {
      return recommendationData?.original_filename;
    }
  };

  return (
    <div className={`flex shrink grow basis-0 w-[1%] flex-col`}>
      {isDataLoading ? (
        <>
          <div
            className="flex flex-col justify-start items-center gap-10 text-md 
          font-semibold w-full h-full pt-20"
          >
            <SioLoader />
            {/* <svg width={0} height={0}>
            <defs>
              <linearGradient
                id="my_gradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor="#e01cd5" />
                <stop offset="100%" stopColor="#1CB5E0" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress
            sx={{
              "svg circle": { stroke: "url(#my_gradient)" },
              size: "20px",
            }}
          /> */}
          </div>
        </>
      ) : !isDataLoading && (!recommendationData || !recData) ? (
        <div className="flex flex-col justify-center items-center h-full w-full p-4 md:p-6">
          <div className="text-center">
            <p className="text-sm md:text-base text-primary-dark mb-4">
              No suggestions found. To get started, please upload your files on
              the Datasource page.
            </p>
          </div>

          <div className="flex justify-center items-center">
            <Button
              variant="secondary"
              className="lg:flex"
              size={"sm"}
              onClick={() => {
                navigate("/data-source");
              }}
            >
              Data Source
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-between items-md-center p-3 md:p-7 md:pb-3">
            <div className="flex gap-3 md:gap-4 items-md-center">
              <i className="sio-circle-icon bg-secondary/20 size-7 md:size-10 shrink-0">
                <SioIcon name="trend" size={24} />
              </i>
              <div>
                <h2 className="font-semibold text-base md:text-xl text-primary-dark mb-1">
                  {recommendationData?.category?.replace(
                    /[^a-zA-Z0-9 ]/g,
                    ""
                  ) || ""}
                </h2>
                <ul className="flex divide-x text-xs md:text-sm divide-dark leading-none flex-wrap">
                  <li className="md:pr-2 my-0 text-muted-dark">
                    Received on {calculateTime(recommendationData?.created_at)}
                  </li>
                  <Tooltip
                    title={
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {handleFileName().map((name, index) => (
                          <span key={index}>
                            {name}
                            {index !== handleFileName().length - 1 && ","}
                          </span>
                        ))}
                      </div>
                    }
                    componentsProps={{
                      tooltip: {
                        sx: {
                          maxHeight: "300px",
                          overflowY: "auto",
                          pointerEvents: "auto",
                          fontSize: "12px",
                          padding: "8px 12px",

                          "& .MuiTooltip-tooltip": {
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                          },
                          "&::-webkit-scrollbar": {
                            width: "2px",
                          },
                          "&::-webkit-scrollbar-track": {
                            borderRadius: "8px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            borderRadius: "8px",
                            "&:hover": {
                              backgroundColor: "#666",
                            },
                          },
                        },
                      },
                    }}
                    interactive
                  >
                    <li
                      className="px-2 pe-4 xs:my-1 sm:my-0 text-muted-dark hidden md:block 
                      w-[13rem] truncate"
                    >
                      {handleFileName().join(", ")}
                    </li>
                  </Tooltip>
                </ul>
              </div>
            </div>
            <div className="flex gap-1 md:gap-3">
              <Tooltip title="Share via Email">
                <Button
                  fab
                  outline
                  variant="dark"
                  onClick={() => handleShare()}
                >
                  <SioIcon name="share" size={16} />
                </Button>
              </Tooltip>
              <PDFGenerator
                markDownContainerData={recData}
                currentFileName={recommendationData?.category}
                isRenderingDownLoadButton={isRenderingDownLoadButton}
                setIsRenderingDownLoadButton={setIsRenderingDownLoadButton}
                isFromAskMe={false}
              />
              <Tooltip title="Clear Conversations">
                <Button
                  fab
                  outline
                  variant="dark"
                  onClick={async () => {
                    handleRemoveModalClickOpen();
                  }}
                >
                  <SioIcon name="clear" size={18} />
                </Button>
              </Tooltip>

              <Dialog
                open={openRemoveModal}
                onClose={() => handleRemoveModalClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <div className="max-w-md flex justify-center items-center text-center p-8 flex-col">
                  <SioIcon name="delete" size={80} className="mb-5" />
                  <h1 className="text-primary-dark text-xl mb-7">
                    Are you sure you want to clear conversation?
                  </h1>
                  <div className="gap-4 flex">
                    <Button
                      variant="secondary"
                      onClick={async () => {
                        updatedSessionId.current = null;
                        const { message, data } = await deleteConversations(
                          recId.current
                        );
                        if (data) {
                          setRecData([
                            {
                              response: data?.content,
                              created_at: data?.created_at,
                              id: data?.id,
                            },
                          ]);

                          let savedAIChildObject;

                          savedAIChildObject = {
                            qualitative_saved:
                              data?.follow_up_questions?.qualitative_saved,
                            quantitative_saved:
                              data?.follow_up_questions?.quantitative_saved,
                          };

                          setAiChildRecommendations([
                            data?.follow_up_questions?.qualitative_question,
                            data?.follow_up_questions?.quantitative_question,
                          ]);

                          setSavedAiChild(savedAIChildObject);
                        }
                        handleRemoveModalClose();
                        setPromptData([]);
                        toast(message, {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: false,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "dark",
                        });
                      }}
                      size={"sm"}
                    >
                      Yes, Remove
                    </Button>
                    <Button
                      size={"sm"}
                      onClick={() => handleRemoveModalClose()}
                      className="text-black"
                    >
                      Not Now
                    </Button>
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
          <div
            id="container"
            ref={divRef}
            className={`overflow-y-scroll scrollbar-none p-3 md:p-7 pt-0`}
            onScroll={(e) => handleScroll(e)}
          >
            <div className={`flex flex-col`}>
              {
                <MarkdownComponent
                  markDownContainerData={recData}
                  loadingPromptResponse={loadingPromptResponse}
                  session_id={updatedSessionId.current}
                  filename={currentFileName}
                  setUpdatedRecData={setRecData}
                  recId={recId}
                  setAiChildRecommendations={setAiChildRecommendations}
                  isInitialRec={recData.length > 1 ? false : true}
                  setFilteredSuggestions={setFilteredSuggestions}
                  category={recommendationData?.category}
                  parentContainer={"suggestions"}
                />
              }
            </div>
          </div>
          <div className="flex flex-col rounded-t-lg shadow-inputField py-3 mt-auto">
            {aiChildRecommendations && aiChildRecommendations.length > 0 && (
              <div className="flex justify-around text-xs gap-2 md:gap-5 mx-2 md:mx-7 mb-2 md:mb-5">
                {APIData &&
                  APIData.filter(
                    (data) =>
                      data?.payload?.apiSessionId ===
                        updatedSessionId?.current && data.status
                  ).length === 0 &&
                  suggestionsRefresh.filter(
                    (data) =>
                      data?.payload?.apiSessionId ===
                        updatedSessionId?.current && data.status
                  ).length === 0 &&
                  aiChildRecommendations?.map(
                    (value, index) =>
                      index <= 1 && (
                        <div
                          key={index}
                          className="flex relative w-1/2 overflow-hidden"
                        >
                          <div className="h-7 w-7 p-1 absolute bg-darkGreen text-white rounded-tl-lg rounded-br-lg left-0">
                            <SioIcon name="suggestionPromt" size={20} />
                          </div>
                          <div
                            onClick={async () => {
                              setIsLoading(true);

                              setScrollToTop(false);

                              const savedStatusType =
                                index === 0
                                  ? "qualitative_question"
                                  : "quantitative_question";

                              const isQuestionSaved =
                                (savedAiChild?.qualitative_saved === true &&
                                  index === 0) ||
                                (savedAiChild?.quantitative_saved === true &&
                                  index === 1)
                                  ? true
                                  : false;

                              await fileUploadRequest(
                                value,
                                savedStatusType,
                                isQuestionSaved
                              );

                              if (
                                (savedAiChild?.qualitative_saved === true &&
                                  index === 0) ||
                                (savedAiChild?.quantitative_saved === true &&
                                  index === 1)
                                  ? true
                                  : false
                              ) {
                                await saveAiChildRecommendations(
                                  recData,
                                  messageId,
                                  index === 0
                                    ? "qualitative_question"
                                    : "quantitative_question"
                                );
                              }
                              setSavedAiChild([]);
                              setPromptData([value]);
                              setIsLoading(false);
                            }}
                            className="bg-secondary-200 rounded-lg p-1 ps-9 md:p-3 md:ps-9 hover:cursor-pointer flex items-start grow"
                          >
                            <SioTooltipClampedText text={value} lines={2} />
                            <button
                              className="ms-auto"
                              onClick={async (event) => {
                                event.stopPropagation();

                                try {
                                  const data2 = await bookmarkAiMessage(
                                    messageId,
                                    index === 0
                                      ? "qualitative_question"
                                      : "quantitative_question",
                                    recData.length > 1
                                      ? "message_id"
                                      : "recommendation_id"
                                  );

                                  if (
                                    (index === 0 &&
                                      !data2?.qualitative_bookmarked) ||
                                    (index === 1 &&
                                      !data2?.quantitative_bookmarked)
                                  ) {
                                    const { saved_messages } =
                                      await getSavedQuestions();
                                    saved_messages.forEach(async (message) => {
                                      if (
                                        message.content === value &&
                                        message.type === "human"
                                      ) {
                                        await bookmarkQuestions(message?.id);
                                        setFilteredRec((prevData) => {
                                          const updatedData = prevData.filter(
                                            (item) =>
                                              item.id !== message.id ||
                                              item.content !== message.content
                                          );
                                          return updatedData;
                                        });
                                      }
                                    });
                                  }

                                  let savedAIChildObject;

                                  savedAIChildObject = {
                                    qualitative_saved:
                                      data2?.qualitative_bookmarked,
                                    quantitative_saved:
                                      data2?.quantitative_bookmarked,
                                  };

                                  setSavedAiChild(savedAIChildObject);

                                  const sessionSavedMessagesAPIData =
                                    localStorage.getItem(
                                      "sessionSavedMessages"
                                    );

                                  if (
                                    (index === 0 &&
                                      actualSavedStatus.qualitative_saved ===
                                        savedAiChild.qualitative_saved) ||
                                    (index === 1 &&
                                      actualSavedStatus.quantitative_saved ===
                                        savedAiChild.quantitative_saved)
                                  ) {
                                    const data =
                                      await saveAiChildRecommendations(
                                        recData,
                                        messageId,
                                        index === 0
                                          ? "qualitative_question"
                                          : "quantitative_question"
                                      );

                                    if (recData.length === 1) {
                                      const {
                                        qualitative_bookmarked,
                                        quantitative_bookmarked,
                                        qualitative_saved,
                                        quantitative_saved,
                                      } = await getInitialChildSavedStatus(
                                        recId.current
                                      );

                                      setActualSavedStatus({
                                        qualitative_saved: qualitative_saved,
                                        quantitative_saved: quantitative_saved,
                                      });

                                      setSavedAiChild({
                                        qualitative_saved:
                                          qualitative_bookmarked,
                                        quantitative_saved:
                                          quantitative_bookmarked,
                                      });
                                    } else {
                                      setActualSavedStatus({
                                        qualitative_saved:
                                          data?.qualitative_question ||
                                          data?.qualitative_saved,
                                        quantitative_saved:
                                          data?.quantitative_question ||
                                          data?.quantitative_saved,
                                      });
                                    }

                                    if (sessionSavedMessagesAPIData) {
                                      const parsedData = JSON.parse(
                                        sessionSavedMessagesAPIData
                                      );
                                      if (
                                        (index === 0 &&
                                          (data?.qualitative_question ||
                                            data?.qualitative_saved)) ||
                                        (index === 1 &&
                                          (data?.quantitative_question ||
                                            data?.quantitative_saved))
                                      ) {
                                        parsedData[value] = "saved";
                                      } else {
                                        if (parsedData[value]) {
                                          delete parsedData[value];
                                        }
                                        setFilteredRec((data) => {
                                          const updatedData = data.filter(
                                            (rec) => rec.content !== value
                                          );
                                          return updatedData;
                                        });
                                      }
                                      localStorage.setItem(
                                        "sessionSavedMessages",
                                        JSON.stringify(parsedData)
                                      );
                                    } else {
                                      localStorage.setItem(
                                        "sessionSavedMessages",
                                        JSON.stringify({
                                          [value]:
                                            data?.qualitative_question ||
                                            data?.quantitative_question ||
                                            data?.qualitative_saved ||
                                            data?.quantitative_saved
                                              ? "saved"
                                              : "unsaved",
                                        })
                                      );
                                    }

                                    toast(
                                      `Your question has been ${
                                        (index === 0 &&
                                          (data?.qualitative_saved ||
                                            data?.qualitative_question)) ||
                                        (index === 1 &&
                                          (data?.quantitative_saved ||
                                            data?.quantitative_question))
                                          ? `saved`
                                          : `unsaved`
                                      } successfully.`,
                                      {
                                        icon: (
                                          <SioIcon
                                            name="check"
                                            size={16}
                                            color="blue"
                                          />
                                        ),
                                      }
                                    );
                                  }
                                } catch (err) {
                                  console.error(err);
                                  toast.error("Filed to save the question", {
                                    icon: (
                                      <SioIcon
                                        name="close"
                                        size={16}
                                        color="blue"
                                      />
                                    ),
                                  });
                                }
                              }}
                            >
                              {(index === 0 &&
                                savedAiChild?.qualitative_saved) ||
                              (index === 1 &&
                                savedAiChild?.quantitative_saved) ? (
                                <SioIcon
                                  name="saved"
                                  className="-mr-[4px] -mt-0.5"
                                  size={18}
                                />
                              ) : (
                                <SioIcon
                                  name="save"
                                  className="-mr-[4px] -mt-0.5"
                                  size={18}
                                />
                              )}
                            </button>
                          </div>
                        </div>
                      )
                  )}
              </div>
            )}
            <div className="mx-2 md:mx-7">
              <div className={`sio-card-shadow flex items-center`}>
                <TextField
                  ref={inputRef}
                  inputRef={inputRef}
                  multiline
                  value={prompt}
                  minRows={1}
                  maxRows={8}
                  id="prompt_data"
                  className="w-[100%] focus:outline-none pl-2 text-sm h-[100%] scrollbar-none
                  3xl:text-[17px] 3xl:h-[100%]"
                  placeholder="Ask me Anything"
                  onChange={handlePrompt}
                  onKeyDown={handleKeyDown}
                  onPaste={handlePaste}
                  autoComplete="off"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "8px 8px 8px 16px",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
                <button
                  disabled={
                    APIData.filter(
                      (data) =>
                        data.payload.apiSessionId ===
                          updatedSessionId.current && data.status
                    ).length > 0 &&
                    suggestionsRefresh.filter(
                      (data) =>
                        data?.payload?.apiSessionId ===
                          updatedSessionId?.current && data.status
                    ).length > 0
                  }
                  className={`soi-btn soi-btn-fab btn-primary shrink-0 my-2 me-2 self-end ${
                    APIData.filter(
                      (data) =>
                        data.payload.apiSessionId ===
                          updatedSessionId.current && data.status
                    ).length > 0 ||
                    suggestionsRefresh.filter(
                      (data) =>
                        data?.payload?.apiSessionId ===
                          updatedSessionId?.current && data.status
                    ).length > 0
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  onClick={async () => {
                    if (
                      APIData.filter(
                        (data) =>
                          data.payload.apiSessionId ===
                            updatedSessionId.current && data.status
                      ).length > 0 ||
                      suggestionsRefresh.filter(
                        (data) =>
                          data?.payload?.apiSessionId ===
                            updatedSessionId?.current && data.status
                      ).length > 0
                    )
                      return;

                    if (!prompt || prompt.trim().length === 0) {
                      toast("Please provide valid input");
                      return;
                    }

                    const promptText =
                      document.getElementById("prompt_data").value;

                    setScrollToTop(false);
                    setPrompt("");
                    await fileUploadRequest(promptText);
                    setPromptData([prompt]);
                    setSavedAiChild([]);
                  }}
                >
                  <SioIcon name="submit" size={16} />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SuggestionsContainer;
