/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import SioIcon from "../../components/icons/IconLibrary";
import { TextField } from "@mui/material";
import Button from "../../components/button";
import ProfilePhotoUploader from "../../components/ProfilePhotoUploader";
import { getUserProfile, updateUserProfile } from "../../apis/profile/index";
import { checkAndRefreshToken } from "../../utils/authUtils";
import { toast } from "react-toastify";
import { getCookie } from "../../utils/cookieHelper";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const ProfileSettings = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [userRole, setUserRole] = useState(getCookie("user_role"));
  const [user_uuid, setUserUuid] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstNameChanged, setIsFirstNameChanged] = useState(false);
  const [isLastNameChanged, setIsLastNameChanged] = useState(false);
  const [isPictureChanged, setIsPictureChanged] = useState(false);
  const navigate = useNavigate();

  const [fieldError, setfieldError] = useState({
    firstName: "",
    lastName: "",
  });

  const fetchUserProfile = async () => {
    try {
      const token = await checkAndRefreshToken();

      const getUserProfileData = await getUserProfile(token);

      if (getUserProfileData.status === 200) {
        setFirstName(getUserProfileData.data?.first_name);
        setLastName(getUserProfileData.data?.last_name);
        setEmail(getUserProfileData.data?.email);
        setOrganization(getCookie("org"));
        setUserRole(getCookie("user_role"));
        setUserUuid(getUserProfileData.data?.user_uuid);
        if (
          getUserProfileData?.data?.url === "no file" ||
          getUserProfileData?.data?.url === null
        ) {
          setProfilePhoto(null);
        } else {
          setProfilePhoto(getUserProfileData.data?.url);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    fetchUserProfile();
  }, []);

  const [profileImage, setProfileImage] = useState(null);

  const handleImageChange = (newImage) => {
    setProfileImage(newImage);
    setIsPictureChanged(true);
    toast.success(
      "This is a preview. Click 'Save' to update your profile photo!",
      {
        theme: "dark",
        icon: <SioIcon name="check" size={16} color="blue" />,
      }
    );
  };

  const handleSubmit = async () => {
    if (!isFirstNameChanged && !isLastNameChanged && !isPictureChanged) {
      toast.error("No changes detected to save", {
        theme: "dark",
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      return;
    }
    if (firstName === "") {
      setfieldError((prev) => ({
        ...prev,
        firstName: "First name can't be blank",
      }));
      return;
    }
    if (lastName === "") {
      setfieldError((prev) => ({
        ...prev,
        lastName: "Last name can't be blank",
      }));
      return;
    }

    const token = await checkAndRefreshToken();
    const formData = new FormData();
    formData.append("user_uuid", user_uuid);
    if (profileImage) {
      formData.append("avatar", profileImage);
    }
    if (isLastNameChanged) {
      formData.append("last_name", lastName);
    }
    if (isFirstNameChanged) {
      formData.append("first_name", firstName);
    }

    const changeUserProfileDataRes = await updateUserProfile(formData, token);

    if (changeUserProfileDataRes?.status === 200) {
      setIsFirstNameChanged(false);
      setIsLastNameChanged(false);
      setIsPictureChanged(false);

      toast.success("Profile updated successfully.", {
        theme: "dark",
        icon: <SioIcon name="check" size={16} color="blue" />,
      });
      fetchUserProfile();
    } else {
      toast.error(changeUserProfileDataRes?.data?.Status, {
        theme: "dark",
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
    }
  };

  const roles = {
    "school-admin": { icon: "schoolLeader", label: "School Leader" },
    teacher: { icon: "teacher", label: "Teacher" },
    "district-admin": { icon: "districtAdmin", label: "District Admin" },
  };

  return (
    <div className="bg-white h-full rounded-lg justify-center">
      <div className="flex flex-col h-full">
        <div className="items-start md:items-center flex shadow-md p-3 !pb-0 md:p-5 relative z-10">
          <div>
            <h1 className="font-normal text-lg md:text-xl text-primary-dark flex gap-3 items-center mb-4">
              <button
                className="soi-btn soi-btn-fab soi-btn-flat btn-dark size-9"
                onClick={() => navigate(-1)}>
                <SioIcon name="leftArrow" size={18} />
              </button>{" "}
              Profile Settings
            </h1>
          </div>
        </div>
        <div className="overflow-auto">
          {isLoading ? (
            <div
              className="flex flex-col justify-start items-center gap-10 text-md 
           font-semibold w-full h-full pt-20">
              <svg width={0} height={0}>
                <defs>
                  <linearGradient
                    id="my_gradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%">
                    <stop offset="0%" stopColor="#e01cd5" />
                    <stop offset="100%" stopColor="#1CB5E0" />
                  </linearGradient>
                </defs>
              </svg>
              <CircularProgress
                sx={{
                  "svg circle": { stroke: "url(#my_gradient)" },
                  size: "20px",
                }}
              />
            </div>
          ) : (
            <from>
              <div className="p-3 md:p-8 max-w-3xl">
                <div className="flex flex-col sm:flex-row">
                  <div className="flex-shrink-0 me-8 mb-5 mb-md-0">
                    <ProfilePhotoUploader
                      onImageChange={handleImageChange}
                      img={profilePhoto}
                      userName={firstName + " " + lastName}
                    />
                  </div>
                  <div className="mt-3 flex-grow-1 w-full">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-6 mb-8">
                      <TextField
                        label="First Name"
                        name="firstName"
                        value={firstName}
                        onChange={(e) => {
                          setIsFirstNameChanged(true);
                          setFirstName(e.target.value);
                          setfieldError((prev) => ({
                            ...prev,
                            firstName: "",
                          }));
                        }}
                        fullWidth
                        error={!!fieldError.firstName}
                        helperText={fieldError.firstName}
                      />
                      <TextField
                        label="Last Name"
                        name="lastName"
                        value={lastName}
                        onChange={(e) => {
                          setIsLastNameChanged(true);
                          setLastName(e.target.value);
                          setfieldError((prev) => ({
                            ...prev,
                            lastName: "",
                          }));
                        }}
                        fullWidth
                        error={!!fieldError.lastName}
                        helperText={fieldError.lastName}
                      />
                      <TextField
                        label="Business Email Address"
                        name="userOrgemail"
                        value={email}
                        fullWidth
                        InputProps={{
                          disabled: true,
                        }}
                      />
                      <TextField
                        label="Organization Name"
                        name="userOrgName"
                        value={organization}
                        fullWidth
                        InputProps={{
                          disabled: true,
                        }}
                      />
                    </div>
                    <div className="mb-8">
                      <p className="text-primary-dark text-xs mb-2">Role</p>
                      <div className="grid auto-cols-max grid-flow-col gap-x-2 sm:gap-x-3 gap-y-2">
                        {userRole ? (
                          <Button
                            outline
                            variant="dark"
                            className="sio-btn-icon flex-col md:flex-row sio-btn-hover-primary cursor-default gap-2 font-semibold sio-btn-active">
                            <SioIcon
                              name={roles[userRole]?.icon}
                              className="hover:fill-dark/75"
                              size="24px"
                            />
                            {userRole}
                          </Button>
                        ) : null}
                      </div>
                    </div>
                    <Button variant="secondary" onClick={handleSubmit}>
                      Save Profile
                    </Button>
                  </div>
                </div>
              </div>
            </from>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
