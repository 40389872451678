/* eslint-disable react-hooks/exhaustive-deps */
import { ThemeProvider } from "@emotion/react";
import { Menu, MenuItem, TextField, Tooltip } from "@mui/material";
import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addMessageToSession,
  bookmarkAiMessage,
  bookmarkQuestions,
  getChatHistory,
  getSavedQuestions,
  saveAiChildRecommendations,
  startConversation,
} from "../../apis/recommendation/recommendation";
import Button from "../../components/button";
import ChatShimmer from "../../components/chat/ChatShimmer";
import SioIcon from "../../components/icons/IconLibrary";
import { DataContext } from "../../components/layout/layout";
import MarkdownComponent from "../../components/MarkdownComponent";
import PDFGenerator from "../../components/pdf/buttons/DownloadPdf";
import SioTooltipClampedText from "../../components/TooltipClampedText";
import { lightTheme } from "../../theme";
import { getUserNames } from "../../utils";
import { checkAndRefreshToken } from "../../utils/authUtils";

const AskMeAnything = () => {
  const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  const AMAContainerData = JSON.parse(
    sessionStorage.getItem("AMAContainerData")
  );

  const {
    sessionId,
    // promptData,
    status,
    promptInput,
    qualitativeQuestion,
    quantitativeQuestion,
    message_id,
    // qualitativeSaved,
    // quantitativeSaved,
  } = location.state || AMAContainerData;

  const {
    setIsPromptResponseGenerated,
    setPromptData,
    setHighlightField,
    setIsLoadingAMAData,
    AMAAddMessageData,
    setAMAAddMessageData,
    AMARecData,
    setAMARecData,
    AMARefreshQuestion,
    AMAEditQuestion,
    setFilteredRec,
    totalFileNames,
  } = useContext(DataContext);

  const [aiChildRec, setAiChildRec] = useState([]);
  const [heading, setHeading] = useState(promptInput);

  const [isLoadingChats, setIsLoadingChats] = useState(false);

  const [scrollToTop, setScrollToTop] = useState(false);
  const [savedAiChild, setSavedAiChild] = useState(null);
  const [isQuestionSaved, setIsQuestionSaved] = useState(false);

  const divRef = useRef(null);
  const navigate = useNavigate();
  const [loadingPromptResponse, setLoadingPromptResponse] = useState(false);
  const [prompt, setPrompt] = useState(null);
  const inputRef = useRef(null);
  const [filenames, setFilenames] = useState(null);
  const updatedSessionId = useRef(sessionId);
  const recId = useRef(null);
  const PER_PAGE = 10;
  const [responseMessageId, setResponseMessageId] = useState(null);
  const [actualSavedStatus, setActualSavedStatus] = useState(null);

  const [isRenderingDownLoadButton, setIsRenderingDownLoadButton] =
    useState(false);

  const fullUrl = window.location.href;
  const modifiedURL = fullUrl.split("/").slice(0, 3).join("/");

  const handleShare = async () => {
    const newUrl = new URL(modifiedURL);
    newUrl.pathname += "message";
    newUrl.searchParams.append("sessionId", sessionId);
    const { data } = await getUserNames();
    newUrl.searchParams.append("user_id", data?.user?.id);
    const encodedShareUrl = encodeURIComponent(newUrl);

    const emailShareUrl = `mailto:?subject=Check out this data!&body=Check out this link: ${encodedShareUrl}`;
    window.location.href = emailShareUrl;
  };

  useEffect(() => {
    const isRefreshed = sessionStorage.getItem("isRefresh");
    setIsLoadingAMAData(false);
    if (isRefreshed === "true") {
      sessionStorage.removeItem("isRefresh");
      setHighlightField("Ask Anything");
    }

    const handleBeforeUnload = () => {
      sessionStorage.setItem("isRefresh", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    setHighlightField("Ask Anything");
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const scrollToResponse = () => {
      if (divRef.current && !scrollToTop) {
        const container = divRef.current;
        const lastDiv = container.lastElementChild.lastElementChild;
        if (lastDiv) {
          lastDiv.scrollIntoView({ behavior: "smooth", block: "start" });
          const scrollPosition =
            lastDiv.offsetTop +
            lastDiv.offsetHeight -
            lastDiv.getBoundingClientRect().height -
            container.offsetHeight;

          container.scrollTo({
            top: scrollPosition + 90,
            behavior: "smooth",
          });
        }
      }
    };
    scrollToResponse();
    updatedSessionId.current = sessionId;
  }, [AMARecData, location.state]);

  useEffect(() => {
    setHeading(promptInput);
    setPrompt("");
    if (totalFileNames)
      setFilenames(
        totalFileNames && totalFileNames.includes(",")
          ? totalFileNames.split(",")
          : Array.isArray(totalFileNames)
          ? [...totalFileNames]
          : [totalFileNames]
      );

    if (qualitativeQuestion && quantitativeQuestion) {
      // setAiChildRec([qualitativeQuestion, quantitativeQuestion]);
      // setSavedAiChild({
      //   qualitative_saved: qualitativeSaved,
      //   quantitative_saved: quantitativeSaved,
      // });
    }
    getMessageChatHistory();
  }, [location.state]);

  const getPromptResponse = async (value) => {
    try {
      setLoadingPromptResponse(true);
      const token = await checkAndRefreshToken();
      if (!token) {
        navigate("/login");
        return;
      }

      const processResponse = (data2) => {
        setAMARecData((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[updatedArray.length - 1] = {
            response: data2?.answer,
            id: data2.message_id,
            session_id: updatedSessionId.current,
          };
          return updatedArray;
        });

        if (
          data2 &&
          data2?.qualitative_question !== null &&
          data2?.quantitative_question !== null
        ) {
          const aiRec = [
            data2?.qualitative_question,
            data2?.quantitative_question,
            data2?.qualitative_saved,
            data2?.quantitative_saved,
          ];
          setAiChildRec(aiRec);
        }
      };

      let newData;

      setAMAAddMessageData((prevData) => {
        newData = [
          ...prevData,
          {
            status: true,
            apiCallname: "AddMessage",
            payload: {
              apiPrompt: value,
              apiSessionId: updatedSessionId.current,
              apiRecId: AMARecData[0]?.id,
            },
          },
        ];
        return newData;
      });

      const data2 = await addMessageToSession(
        updatedSessionId.current,
        value,
        filenames,
        null,
        "true"
      );

      setAMAAddMessageData((prevData) => {
        const updatedStatus = prevData.map((data) => {
          return data.payload.apiPrompt === data2?.user_question
            ? { ...data, status: false }
            : data;
        });
        if (
          updatedStatus.filter(
            (data) =>
              data.payload.apiPrompt === data2.user_question && !data.status
          ).length > 0
        ) {
          processResponse(data2);
        } else {
          setAMARecData((prevData) => prevData);
        }
        return updatedStatus;
      });

      setLoadingPromptResponse(false);
      setPrompt("");

      setIsPromptResponseGenerated((prevState) => !prevState);
    } catch (error) {
      console.error(error);
      setAMARecData((prevArray) => prevArray.slice(0, -2));
      toast.error("Error:", error, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
    }
  };

  const getMessageChatHistory = async () => {
    setIsLoadingChats(true);
    setAMARecData([]);
    try {
      const nextQueryParams = new URLSearchParams({
        session_id: sessionId,
        per_page: PER_PAGE,
      });

      const { data } = await getUserNames();
      nextQueryParams.append("user_id", data?.user?.id);

      let allHistory = [];
      let currentPage = 1;

      while (true) {
        nextQueryParams.set("page", currentPage);
        const { history, page, total_pages } = await getChatHistory(
          nextQueryParams
        );

        if (history?.length) {
          allHistory = [...allHistory, ...history];
        }

        if (page >= total_pages) break;
        currentPage++;
      }

      if (allHistory.length) {
        const updatedArray = allHistory
          .map((item) => {
            if (item.type === "human") {
              return {
                input: { content: item.content, id: item?.id, isSaved: false },
                id: item?.id,
                saved_status: item?.saved_status,
                session_id: item?.session_id,
              };
            } else if (item.type === "ai") {
              return {
                response: item.content,
                id: item.id,
                qualitative_question: item?.qualitative_question,
                quantitative_question: item?.quantitative_question,
                qualitative_saved: item?.qualitative_saved,
                quantitative_saved: item?.quantitative_saved,
                qualitative_bookmarked: item?.qualitative_bookmarked,
                quantitative_bookmarked: item?.quantitative_bookmarked,
                saved_status: item?.saved_status,
                likes: item?.likes,
                disLikes: item?.dislikes,
                session_id: item?.session_id,
              };
            }
            return null;
          })
          .filter(Boolean);

        const reversedArray = updatedArray.reverse().slice(1);
        if (reversedArray.length) {
          const lastItem = reversedArray[reversedArray.length - 1];
          setAiChildRec([
            lastItem.qualitative_question,
            lastItem.quantitative_question,
          ]);
          setSavedAiChild({
            qualitative_saved: lastItem.qualitative_bookmarked,
            quantitative_saved: lastItem.quantitative_bookmarked,
          });

          setActualSavedStatus({
            qualitative_saved: lastItem.qualitative_saved,
            quantitative_saved: lastItem.quantitative_saved,
          });
          setResponseMessageId(lastItem.id);

          if (updatedArray[0]?.id === message_id) {
            setIsQuestionSaved(status);
          }
        }
        if (
          AMAAddMessageData.filter(
            (data) =>
              data.payload.apiSessionId === updatedSessionId.current &&
              data.status
          ).length > 0 ||
          AMARefreshQuestion.filter(
            (data) => data.payload.apiSessionId === sessionId && data.status
          ).length > 0 ||
          AMAEditQuestion.filter(
            (data) => data.payload.apiSessionId === sessionId && data.status
          ).length > 0
        ) {
          if (
            AMAAddMessageData.filter(
              (data) =>
                data.payload.apiSessionId === updatedSessionId.current &&
                data.status
            ).length > 0
          ) {
            reversedArray.push(
              {
                input: {
                  content: AMAAddMessageData.filter(
                    (data) =>
                      data.payload.apiSessionId === updatedSessionId.current &&
                      data.status
                  )[0].payload.apiPrompt,
                  id: null,
                  isSaved: false,
                },
              },
              {
                response: "Loading...",
              }
            );
          } else {
            if (
              AMAEditQuestion.filter(
                (data) => data.payload.apiSessionId === sessionId && data.status
              ).length > 0
            ) {
              const getIndex = AMAEditQuestion.filter(
                (data) => data.payload.apiSessionId === sessionId && data.status
              )[0].index;

              reversedArray.splice(getIndex + 1, reversedArray.length, {
                response: "Loading...",
              });
            } else {
              const getIndex = AMARefreshQuestion.filter(
                (data) => data.payload.apiSessionId === sessionId && data.status
              )[0].index;

              reversedArray.splice(getIndex, reversedArray.length, {
                response: "Loading...",
              });
            }
          }
          setAMARecData(() => {
            return reversedArray;
          });
        } else {
          setAMARecData(reversedArray);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Error: " + error.message, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
    } finally {
      setIsLoadingChats(false);
    }
  };

  const clearPrompt = () => {
    document.getElementById("prompt_data").value = "";
  };

  const fileUploadRequest = async () => {
    setLoadingPromptResponse(true);
    const promptText = document.getElementById("prompt_data").value;
    clearPrompt();

    try {
      setAMARecData((prevArray) => {
        const updatedArray = [
          ...prevArray,
          { input: { content: promptText, id: null } },
          { response: "Loading..." },
        ];
        return updatedArray;
      });

      const token = await checkAndRefreshToken();
      if (!token) navigate("/login");

      const promptValue = promptText;

      if (!updatedSessionId.current) {
        const data = await startConversation(recId.current, token);
        updatedSessionId.current = data.session_id;
        const data2 = await addMessageToSession(
          updatedSessionId.current,
          promptValue,
          filenames,
          null,
          "true"
        );

        if (
          AMAAddMessageData.filter(
            (data) =>
              data.payload.apiPrompt === data2.user_question && !data.status
          ).length > 0
        ) {
          setAMARecData((prevArray) => {
            const updatedArray = [...prevArray];
            updatedArray[updatedArray.length - 1] = {
              response: data2?.answer,
            };
            return updatedArray;
          });
          if (
            data2 &&
            data2?.qualitative_question !== null &&
            data2.quantitative_question !== null
          ) {
            const aiRec = [
              data2?.qualitative_question,
              data2?.quantitative_question,
              data2?.qualitative_saved,
              data2?.quantitative_saved,
            ];
            setAiChildRec(aiRec);
          }
        }
        updatedSessionId.current = data.session_id;
      } else {
        setAMAAddMessageData((prevData) => [
          ...prevData,
          {
            status: true,
            apiCallname: "AddMessage",
            payload: {
              apiPrompt: promptValue,
              apiSessionId: updatedSessionId.current,
              apiRecId: AMARecData[0]?.id,
            },
          },
        ]);
        const data2 = await addMessageToSession(
          updatedSessionId.current,
          promptValue,
          filenames,
          null,
          "true"
        );

        setAMAAddMessageData((prevData) => {
          const updateStatus = prevData.map((data) => {
            return data.payload.apiPrompt === data2?.user_question
              ? { ...data, status: false }
              : data;
          });
          if (
            updateStatus.filter(
              (data) =>
                data.payload.apiPrompt === data2.user_question && !data.status
            ).length > 0
          ) {
            setAMARecData((prevArray) => {
              const updatedArray = [...prevArray];
              updatedArray[updatedArray.length - 1] = {
                response: data2?.answer,
              };
              return updatedArray;
            });
            if (
              data2 &&
              data2?.qualitative_question !== null &&
              data2.quantitative_question !== null
            ) {
              const aiRec = [
                data2?.qualitative_question,
                data2?.quantitative_question,
                data2?.qualitative_saved,
                data2?.quantitative_saved,
              ];
              setAiChildRec(aiRec);
            }
          } else {
            setAMARecData((prevArray) => prevArray);
          }
          return updateStatus;
        });
      }
      setPromptData([]);
      setLoadingPromptResponse(false);
      setSavedAiChild(null);
      setPrompt("");
    } catch (error) {
      setAMARecData((prevArray) => prevArray.slice(0, -2));
      toast.error("Error:", error, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      return null;
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();

    const clipboardText = event.clipboardData.getData("text");
    const cleanedText = clipboardText
      .split("\n")
      .filter((line) => line.trim() !== "")
      .join("\n")
      .trim();

    if (inputRef.current) {
      const textarea = inputRef.current.querySelector("textarea");
      if (textarea) {
        const { selectionStart, selectionEnd } = textarea;

        const currentValue = prompt || "";

        const newValue =
          currentValue.substring(0, selectionStart) +
          cleanedText +
          currentValue.substring(selectionEnd);

        setPrompt(newValue);

        textarea.value = newValue;

        textarea.dispatchEvent(new Event("input", { bubbles: true }));
      }
    }
  };

  const handleKeyDown = async (e) => {
    if (
      e.key === "Enter" &&
      !e.shiftKey &&
      AMAAddMessageData.filter(
        (data) =>
          data.payload.apiSessionId === updatedSessionId.current && data.status
      ).length === 0 &&
      AMARefreshQuestion.filter(
        (data) => data.payload.apiSessionId === sessionId && data.status
      ).length === 0 &&
      AMAEditQuestion.filter(
        (data) =>
          data.payload.apiSessionId === updatedSessionId.current && data.status
      ).length === 0
    ) {
      e.preventDefault();
      if (!prompt || prompt.trim().length === 0) {
        toast.error("Please provide input", {
          icon: <SioIcon name="close" size={16} color="blue" />,
        });
        return;
      }
      setScrollToTop(false);
      await fileUploadRequest();
    } else if (e.key === "Enter" && e.shiftKey) {
      return;
    }
  };

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const settings = [
    {
      name: "Share",
      icon: "share",
    },
    {
      name: "Save",
      icon: "save",
    },
    {
      name: "Download",
      icon: "download",
    },
  ];
  const Mobilesettings = [...settings];

  return (
    <div className="bg-white h-full rounded-lg flex justify-center">
      <div className="max-w-4xl mx-auto w-full flex flex-col px-2 mb-2 md:mb-5 md:px-5 sio-recommendation-contener">
        <div className={`h-full flex flex-col overflow-hidden items-center`}>
          <div className="flex flex-row w-full overflow-hidden h-full">
            <div className={`flex shrink grow basis-0 flex-col w-full`}>
              <div className="flex justify-between items-start pb-4 pt-4 md:pt-9 gap-3 md:gap-4 border-b">
                <div className="flex gap-3 md:gap-4 items-center">
                  <i className="sio-circle-icon self-start bg-secondary/20 size-7 md:size-10 shrink-0">
                    <SioIcon name="quotation" size={18} />
                  </i>
                  <div>
                    <h2 className="font-semibold text-base md:text-xl text-primary-dark self-center">
                      {heading}
                    </h2>
                  </div>
                </div>
                <div className="gap-1 md:gap-3 hidden md:flex self-center">
                  <Tooltip title="Share Link">
                    <Button
                      outline
                      fab
                      variant="dark"
                      className="size-10"
                      onClick={() => handleShare()}
                    >
                      <SioIcon name="share" size={16} />
                    </Button>
                  </Tooltip>

                  <PDFGenerator
                    markDownContainerData={AMARecData}
                    currentFileName={promptInput}
                    isRenderingDownLoadButton={isRenderingDownLoadButton}
                    setIsRenderingDownLoadButton={setIsRenderingDownLoadButton}
                    isFromAskMe={true}
                  />
                  <Tooltip title="Save Response">
                    <Button
                      outline
                      fab
                      variant="dark"
                      className="size-10"
                      onClick={async () => {
                        const { saved_status } = await bookmarkQuestions(
                          message_id
                        );
                        setIsQuestionSaved(saved_status);
                        setPromptData([saved_status]);
                      }}
                    >
                      {isQuestionSaved ? (
                        <SioIcon name="saved" size={16} />
                      ) : (
                        <SioIcon name="save" size={16} />
                      )}
                    </Button>
                  </Tooltip>
                </div>
                <div className="flex md:hidden">
                  <Button
                    outline
                    fab
                    variant="dark"
                    onClick={handleOpenUserMenu}
                  >
                    <SioIcon name="more" size={16} />
                  </Button>
                  <ThemeProvider theme={lightTheme}>
                    <Menu
                      id="menu-appbar"
                      className=""
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      sx={{
                        mt: 4,
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {Mobilesettings.map((setting) =>
                        setting.name === "Save" ? (
                          <MenuItem
                            key="Save"
                            className={isQuestionSaved ? "active" : ""}
                            onClick={async () => {
                              const { saved_status } = await bookmarkQuestions(
                                message_id
                              );
                              setIsQuestionSaved(saved_status);
                              setPromptData([saved_status]);
                              handleCloseUserMenu();
                            }}
                          >
                            <i className="soi-icon">
                              <SioIcon
                                name={isQuestionSaved ? "saved" : "save"}
                                size={14}
                              />
                            </i>
                            Save
                          </MenuItem>
                        ) : (
                          <MenuItem
                            key={setting.name}
                            className={
                              activeMenu === setting.name ? "active" : ""
                            }
                            onClick={() => {
                              setActiveMenu(setting.name);
                              handleCloseUserMenu();
                            }}
                          >
                            <i className="soi-icon">
                              <SioIcon name={setting.icon} size={14} />
                            </i>
                            {setting.name}
                          </MenuItem>
                        )
                      )}
                    </Menu>
                  </ThemeProvider>
                </div>
              </div>

              {isLoadingChats && (
                <div>
                  <ChatShimmer />
                </div>
              )}

              {!isLoadingChats && (
                <div
                  id="container"
                  ref={divRef}
                  className={`overflow-x-hidden overflow-y-scroll scrollbar-none py-3 md:py-5 `}
                >
                  <div className={`flex flex-col `}>
                    {
                      <MarkdownComponent
                        markDownContainerData={AMARecData}
                        loadingPromptResponse={loadingPromptResponse}
                        session_id={updatedSessionId.current}
                        filename={filenames}
                        setUpdatedRecData={setAMARecData}
                        currentFileName={promptInput}
                        isFromAskMePage={true}
                      />
                    }
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-col rounded-t-lg shadow-inputField pt-1 md:pt-3 mt-auto">
            {aiChildRec && aiChildRec.length > 0 && (
              <div className="flex justify-around text-xs gap-2 md:gap-5 mb-2 md:mb-5 ">
                {AMAAddMessageData.filter(
                  (data) =>
                    data.payload.apiSessionId === updatedSessionId.current &&
                    data.status
                ).length === 0 &&
                  AMARefreshQuestion.filter(
                    (data) =>
                      data.payload.apiSessionId === sessionId && data.status
                  ).length === 0 &&
                  AMAEditQuestion.filter(
                    (data) =>
                      data.payload.apiSessionId === sessionId && data.status
                  ).length === 0 &&
                  aiChildRec?.map(
                    (value, index) =>
                      index <= 1 && (
                        <div
                          key={index}
                          className="flex relative  w-1/2 overflow-hidden"
                        >
                          <div className="h-7 w-7 p-1 absolute bg-darkGreen text-white rounded-tl-lg rounded-br-lg left-0">
                            <SioIcon name="suggestionPromt" size={20} />
                          </div>
                          <div
                            onClick={async () => {
                              setAMARecData((prevArray) => {
                                const updatedArray = [
                                  ...prevArray,
                                  {
                                    input: {
                                      content: value,
                                      id: value?.id,
                                    },
                                  },
                                  { response: "Loading..." },
                                ];
                                return updatedArray;
                              });
                              setScrollToTop(false);
                              await getPromptResponse(value);
                            }}
                            className="bg-secondary-200 rounded-lg p-1 ps-9 md:p-3 md:ps-9 hover:cursor-pointer flex items-start grow"
                          >
                            <SioTooltipClampedText text={value} lines={2} />
                            <button
                              className="ms-auto"
                              onClick={async (event) => {
                                event.stopPropagation();
                                try {
                                  const data2 = await bookmarkAiMessage(
                                    responseMessageId,
                                    index === 0
                                      ? "qualitative_question"
                                      : "quantitative_question",
                                    "message_id"
                                  );

                                  if (
                                    (index === 0 &&
                                      !data2?.qualitative_bookmarked) ||
                                    (index === 1 &&
                                      !data2?.quantitative_bookmarked)
                                  ) {
                                    const { saved_messages } =
                                      await getSavedQuestions();
                                    saved_messages.forEach(async (message) => {
                                      if (
                                        message.content === value &&
                                        message.type === "human"
                                      ) {
                                        await bookmarkQuestions(message?.id);
                                        setFilteredRec((prevData) => {
                                          const updatedData = prevData.filter(
                                            (item) =>
                                              item.id !== message.id ||
                                              item.content !== message.content
                                          );
                                          return updatedData;
                                        });
                                      }
                                    });
                                  }

                                  let savedAIChildObject;

                                  savedAIChildObject = {
                                    qualitative_saved:
                                      data2?.qualitative_bookmarked,
                                    quantitative_saved:
                                      data2?.quantitative_bookmarked,
                                  };

                                  setSavedAiChild(savedAIChildObject);

                                  const sessionSavedMessagesAPIData =
                                    localStorage.getItem(
                                      "sessionSavedMessages"
                                    );

                                  if (
                                    (index === 0 &&
                                      actualSavedStatus.qualitative_saved ===
                                        savedAiChild.qualitative_saved) ||
                                    (index === 1 &&
                                      actualSavedStatus.quantitative_saved ===
                                        savedAiChild.quantitative_saved)
                                  ) {
                                    const data =
                                      await saveAiChildRecommendations(
                                        AMARecData,
                                        responseMessageId,
                                        null,
                                        index === 0
                                          ? "qualitative_question"
                                          : "quantitative_question"
                                      );

                                    setActualSavedStatus({
                                      qualitative_saved:
                                        data?.qualitative_question ||
                                        data?.qualitative_saved,
                                      quantitative_saved:
                                        data?.quantitative_question ||
                                        data?.quantitative_saved,
                                    });

                                    if (sessionSavedMessagesAPIData) {
                                      const parsedData = JSON.parse(
                                        sessionSavedMessagesAPIData
                                      );
                                      if (
                                        (index === 0 &&
                                          (data?.qualitative_question ||
                                            data?.qualitative_saved)) ||
                                        (index === 1 &&
                                          (data?.quantitative_question ||
                                            data?.quantitative_saved))
                                      ) {
                                        parsedData[value] = "saved";
                                      } else {
                                        if (parsedData[value]) {
                                          delete parsedData[value];
                                        }
                                      }
                                      localStorage.setItem(
                                        "sessionSavedMessages",
                                        JSON.stringify(parsedData)
                                      );
                                    } else {
                                      localStorage.setItem(
                                        "sessionSavedMessages",
                                        JSON.stringify({
                                          [value]:
                                            data?.qualitative_question ||
                                            data?.quantitative_question ||
                                            data?.qualitative_saved ||
                                            data?.quantitative_saved
                                              ? "saved"
                                              : "unsaved",
                                        })
                                      );
                                    }

                                    toast(
                                      `Your question has been ${
                                        (index === 0 &&
                                          data?.qualitative_saved) ||
                                        (index === 1 &&
                                          data?.quantitative_saved)
                                          ? "saved"
                                          : "unsaved"
                                      } successfully.`,
                                      {
                                        icon: (
                                          <SioIcon
                                            name="check"
                                            size={16}
                                            color="blue"
                                          />
                                        ),
                                      }
                                    );
                                  }

                                  // setSavedAiChild([
                                  //   qualitative_saved,
                                  //   quantitative_saved,
                                  // ]);
                                } catch (err) {
                                  toast.error("Filed to save the question", {
                                    icon: (
                                      <SioIcon
                                        name="close"
                                        size={16}
                                        color="blue"
                                      />
                                    ),
                                  });
                                }
                              }}
                            >
                              {(index === 0 &&
                                savedAiChild?.qualitative_saved) ||
                              (index === 1 &&
                                savedAiChild?.quantitative_saved) ? (
                                <SioIcon
                                  name="saved"
                                  className="-mr-[4px] -mt-0.5"
                                  size={18}
                                />
                              ) : (
                                <SioIcon
                                  name="save"
                                  className="-mr-[4px] -mt-0.5"
                                  size={18}
                                />
                              )}
                            </button>
                          </div>
                        </div>
                      )
                  )}
              </div>
            )}
            <div className="">
              <div className={`sio-card-shadow flex items-center`}>
                <TextField
                  ref={inputRef}
                  onChange={(e) => {
                    setPrompt(e.target.value);
                  }}
                  onPaste={handlePaste}
                  id="prompt_data"
                  multiline
                  minRows={1}
                  maxRows={8}
                  className="w-[100%] focus:outline-none pl-2 text-sm h-[100%]
                  3xl:text-[17px] 3xl:h-[100%]"
                  placeholder="Ask me Anything"
                  onKeyDown={handleKeyDown}
                  autoComplete="off"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "8px 8px 8px 16px",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
                <button
                  disabled={
                    AMAAddMessageData.filter(
                      (data) =>
                        data.payload.apiSessionId === sessionId && data.status
                    ).length > 0 &&
                    AMARefreshQuestion.filter(
                      (data) =>
                        data.payload.apiSessionId === sessionId && data.status
                    ).length > 0 &&
                    AMAEditQuestion.filter(
                      (data) =>
                        data.payload.apiSessionId === sessionId && data.status
                    ).length > 0
                  } // Disable the button when isLoading is true
                  className={`soi-btn soi-btn-fab btn-primary shrink-0 my-2 me-2 self-end ${
                    AMAAddMessageData.filter(
                      (data) =>
                        data.payload.apiSessionId === sessionId && data.status
                    ).length > 0 ||
                    AMARefreshQuestion.filter(
                      (data) =>
                        data.payload.apiSessionId === sessionId && data.status
                    ).length > 0 ||
                    AMAEditQuestion.filter(
                      (data) =>
                        data.payload.apiSessionId === sessionId && data.status
                    ).length > 0
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  onClick={async () => {
                    if (
                      AMAAddMessageData.filter(
                        (data) =>
                          data.payload.apiSessionId === sessionId && data.status
                      ).length > 0 ||
                      AMARefreshQuestion.filter(
                        (data) =>
                          data.payload.apiSessionId === sessionId && data.status
                      ).length > 0 ||
                      AMAEditQuestion.filter(
                        (data) =>
                          data.payload.apiSessionId === sessionId && data.status
                      ).length > 0
                    )
                      return;
                    if (!prompt || prompt.trim().length === 0) {
                      toast("Please provide valid input");
                      return;
                    }

                    setScrollToTop(false);
                    await fileUploadRequest();
                  }}
                >
                  <SioIcon name="submit" size={16} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <Button
          className="soi-btn-fab soi-btn-fab soi-btn-flat btn-lg ms-auto absolute top-3 right-2 lg:right-7 lg:top-7 hidden md:block"
          onClick={() => {
            navigate("/");
          }}
        >
          <SioIcon name="closeLight" size={24} />
        </Button>
      </div>
    </div>
  );
};

export default memo(AskMeAnything);
