/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, Skeleton, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addMessageToSession,
  // getChatHistory,
  getFilesList,
  getPromptMessages,
  getRecByS3Keys,
} from "../../apis/recommendation/recommendation";
import { StrivedContext } from "../../App";
import wave from "../../assets/ic_wave.svg";
import Button from "../../components/button";
import ChatInputField from "../../components/ChatInputField";
import SioIcon from "../../components/icons/IconLibrary";
import { DataContext } from "../../components/layout/layout";
import SioTooltipClampedText from "../../components/TooltipClampedText";
import { devApis } from "../../constants/constant";
import { getUserNames } from "../../utils";
import { checkAndRefreshToken } from "../../utils/authUtils";
import { customFetch } from "../../utils/RefreshTokenInterceptor";
import Loading from "../loading";

import { getCookie } from "../../utils/cookieHelper";
const GreetPage = ({ dataFromSidebar }) => {
  const {
    handleHomePage,
    handleDataFromLanding,
    setHighlightField,
    isPromptResponseGenerated,
    setPromptData,
    isLoadingAMAData,
    setIsLoadingAMAData,
    totalFileNames,
  } = useContext(DataContext);

  const navigate = useNavigate();

  const [fileNames, setFileNames] = useState(null);
  const [historyData, setHistoryData] = useState([]);

  const [recId, setRecId] = useState(null);

  const getFileNames = async () => {
    setFileNames(() => totalFileNames);
  };

  const genericQuestions = [
    {
      content:
        "What students are in the lowest quintile of reading based on the last assessment?",
    },
    {
      content:
        "Group my students by skill gaps in math based on the latest data.",
    },
    {
      content:
        "Show me a list of students that have had more than 5 absences in the last 3 months.",
    },
  ];

  const fetchChatHistory = async () => {
    const token = await checkAndRefreshToken();
    if (!token) navigate("/login");
    try {
      const nextQueryParams = new URLSearchParams();
      const getUUID = await getUserNames();
      const uuid = getUUID.data.org_uuid;
      nextQueryParams.append("org_uuid", uuid);

      const { history } = await getPromptMessages(nextQueryParams);
      const humanData = history.filter((item) => item.type === "human");

      if (history.length > 0) {
        const contentFrequency = humanData.reduce((acc, item) => {
          acc[item.content] = (acc[item.content] || 0) + 1;
          return acc;
        }, {});

        const top4Contents = Object.entries(contentFrequency)
          .sort((a, b) => b[1] - a[1])
          .slice(0, 4)
          .map(([content, frequency]) => ({ content, frequency }));

        setHistoryData([
          ...top4Contents,
          ...genericQuestions.slice(0, 4 - top4Contents.length),
        ]);
      } else {
        const suggestedQuestions = genericQuestions;
        setHistoryData(suggestedQuestions);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error:", error, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      return null;
    }
  };

  const getPromptResponse = async (prompt) => {
    try {
      const nextQueryParams = new URLSearchParams();
      nextQueryParams.append("recommendation_id", recId);
      nextQueryParams.append("page", 1);
      nextQueryParams.append("per_page", 10);

      const { data } = await getUserNames();

      nextQueryParams.append("user_id", data?.user?.id);
      // const accesstoken = await checkAndRefreshToken();

      const promptResponse = await customFetch(
        `${devApis.PYTHON_BASE_URL}/start_conversation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${accesstoken}`,
          },
          body: JSON.stringify({
            recommendation_id: recId,
          }),
        }
      );

      const { session_id } = await promptResponse.json();

      const response = await addMessageToSession(
        session_id,
        prompt,
        fileNames,
        null,
        "true"
      );

      handleHomePage(prompt);
      handleDataFromLanding({ recommendations: [] }, []);
      setPromptData((prevPrompts) => [
        {
          content: prompt,
        },
        ...prevPrompts,
      ]);

      setIsLoadingAMAData(false);

      // const queryParams = new URLSearchParams({
      //   promptInput: prompt,
      // });
      navigate(`/askmeanything`, {
        state: {
          sessionId: session_id,
          promptData: response?.answer,
          promptInput: prompt,
          fileName: fileNames,
          // aiChildRecommendation: [
          //   response?.qualitative_question,
          //   response?.quantitative_question,
          // ],
          qualitativeQuestion: response?.qualitative_question,
          quantitativeQuestion: response?.quantitative_question,
          message_id: response?.message_id,
        },
      });

      // navigate(`/askmeanything?${queryParams.toString()}`);
    } catch (error) {
      toast.error("error:", error.message, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      return null;
    }
  };

  useEffect(() => {
    getFileNames();

    const isRefreshed = sessionStorage.getItem("isRefresh");
    if (isRefreshed === "true") {
      sessionStorage.removeItem("isRefresh");
      setHighlightField("Ask Anything");
    }

    const handleBeforeUnload = () => {
      sessionStorage.setItem("isRefresh", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    setHighlightField("Ask Anything");
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    fetchChatHistory();
  }, [isPromptResponseGenerated]);

  const LoadingRecommendationsMessage = () => {
    return (
      <div className="bg-white h-full rounded-lg flex justify-center">
        <Loading />
      </div>
    );
  };

  if (dataFromSidebar === false) return <LoadingRecommendationsMessage />;

  if (dataFromSidebar && dataFromSidebar?.recommendations)
    navigate("/landingPage");

  return (
    <div className="bg-white h-full rounded-lg flex justify-center">
      {isLoadingAMAData ? (
        <div className="max-w-md flex justify-center items-center text-center flex-col gap-8 font-semibold text-xl">
          Please wait while we generate the response.
          <svg width={0} height={0}>
            <defs>
              <linearGradient
                id="my_gradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor="#e01cd5" />
                <stop offset="100%" stopColor="#1CB5E0" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress
            sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
          />
        </div>
      ) : (
        <div className="max-w-4xl mx-auto w-full flex flex-col px-4 mb-2 md:mb-5 md:px-5 sio-recommendation-contener">
          <div className="mt-5 md:mt-24">
            <div className="sio-circle-icon sio-circle-warning size-16 md:size-20 mb-6 md:mb-9">
              <img
                src={wave}
                alt="wave"
                className=" w-[40px] md:w-[55px] mx-auto justify-center"
              />
            </div>
            <h1 className="text-primary-dark text-xl md:text-4xl mb-3 md:mb-5 font-semibold">
              <p className="text-lg md:text-xl font-manrope font-bold">
                <span className="font-light">Hey</span>{" "}
                {getCookie("userName") || "User"}!
              </p>
              What would you like to know?
            </h1>
            <div className="sio-card-shadow flex items-center mt-5 md:mt-8">
              <ChatInputField
                handleHomePage={handleHomePage}
                isLoading={isLoadingAMAData}
                setIsLoading={setIsLoadingAMAData}
                recId={recId}
                hasFiles={fileNames?.length > 0 ? true : false}
              />
            </div>
            {fileNames && fileNames.length > 0 && (
              <>
                <p className="text-muted-dark font-light text-base mt-5 md:mt-8 mb-4">
                  Or choose from top questions
                </p>
                <div className="grid grid-cols-2 gap-4 mb-3 lg:grid-cols-4">
                  {historyData.length > 0
                    ? historyData.map((item, ind) => {
                        return (
                          <div className="sio-card-sm" key={ind}>
                            <SioTooltipClampedText
                              className="text-sm mb-auto"
                              text={item.content}
                              lines={4}
                            />
                            <Button
                              className="soi-btn-fab"
                              variant="default"
                              onClick={() => {
                                // toast.warning(
                                //   "Please upload a file first to generate the response",
                                //   {
                                //     icon: (
                                //       <SioIcon
                                //         name="close"
                                //         size={16}
                                //         color="blue"
                                //       />
                                //     ),
                                //   }
                                // );

                                setIsLoadingAMAData(true);
                                getPromptResponse(item.content);
                              }}
                            >
                              <SioIcon name="rightArrow" size={14} />
                            </Button>
                          </div>
                        );
                      })
                    : Array.from({ length: 4 }).map((_, index) => {
                        return (
                          <div className="sio-card-sm" key={index}>
                            {Array.from({ length: 4 }).map((_, ind) => {
                              return (
                                <Typography
                                  component="div"
                                  key={ind}
                                  variant="caption"
                                >
                                  <Skeleton />
                                </Typography>
                              );
                            })}
                          </div>
                        );
                      })}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GreetPage;
