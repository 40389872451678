/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, TextField } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { devApis } from "../constants/constant";
import {
  addMessageToSession,
  getFilesList,
} from "../apis/recommendation/recommendation";
import { DataContext } from "./layout/layout";
import SioIcon from "./icons/IconLibrary";
import { customFetch } from "../utils/RefreshTokenInterceptor";

const ChatInputField = ({
  handleHomePage,
  setIsLoading,
  isLoading,
  recId,
  hasFiles,
}) => {
  const [prompt, setPrompt] = useState(null);
  const [fileNames, setFileNames] = useState(null);
  const inputRef = useRef(null);

  const { handleDataFromLanding, setPromptData } = useContext(DataContext);

  useEffect(() => {
    getFileNames();
  }, []);

  const navigate = useNavigate();

  const handlePrompt = (e) => {
    setPrompt(e.target.value);
  };

  const getFileNames = async () => {
    const files = await getFilesList();
    const fileNames = files
      .filter(
        (item) =>
          item.original_file_name.endsWith(".csv") && !item.archive_status
      )
      .map((file) => file?.original_file_name);
    setFileNames(fileNames);
  };

  const fileUploadRequest = async () => {
    try {
      // const nextQueryParams = new URLSearchParams();
      // nextQueryParams.append("recommendation_id", recId);
      // nextQueryParams.append("page", 1);
      // nextQueryParams.append("per_page", 10);

      // const { data } = await getUserNames();

      // nextQueryParams.append("user_id", data?.user?.id);
      // // const newToken = await checkAndRefreshToken();

      // const { history } = await getChatHistory(nextQueryParams);

      let newSessionId;

      const promptResponse = await customFetch(
        `${devApis.PYTHON_BASE_URL}/start_conversation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${newToken}`,
          },
          body: JSON.stringify({
            recommendation_id: recId,
          }),
        }
      );

      const { session_id } = await promptResponse.json();
      newSessionId = session_id;

      if (newSessionId) {
        const response = await addMessageToSession(
          newSessionId,
          prompt,
          fileNames,
          null,
          "true"
        );
        handleHomePage(prompt);
        handleDataFromLanding({ recommendations: [] }, []);
        setPromptData([{ content: prompt }]);

        const queryParams = new URLSearchParams({
          promptInput: prompt,
        });

        navigate(`/askmeanything?${queryParams.toString()}`, {
          state: {
            sessionId: newSessionId,
            promptData: response?.answer,
            promptInput: prompt,
            fileName: fileNames,
            qualitativeQuestion: response?.qualitative_question,
            quantitativeQuestion: response?.quantitative_question,
            message_id: response?.message_id,
          },
        });
        // navigate(`/askmeanything?${queryParams.toString()}`);
      }
    } catch (error) {
      toast.error("error:", error.message, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      return null;
    }
  };

  const handleSubmit = async () => {
    if (!prompt || prompt.trim().length === 0) {
      toast("Please provide valid input", {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      return;
    }
    if (!hasFiles) {
      toast.warning(
        "Please upload a file first to generate the response to provided prompt",
        {
          icon: <SioIcon name="close" size={16} color="blue" />,
        }
      );
      return;
    }

    setIsLoading(true);
    await fileUploadRequest();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSubmit();
    } else if (e.key === "Enter" && e.shiftKey) {
      return;
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();

    const clipboardText = event.clipboardData.getData("text");
    const cleanedText = clipboardText
      .split("\n")
      .filter((line) => line.trim() !== "")
      .join("\n")
      .trim();

    if (inputRef.current) {
      const textarea = inputRef.current.querySelector("textarea");
      if (textarea) {
        const { selectionStart, selectionEnd } = textarea;

        const currentValue = prompt || "";

        const newValue =
          currentValue.substring(0, selectionStart) +
          cleanedText +
          currentValue.substring(selectionEnd);

        setPrompt(newValue);

        textarea.value = newValue;

        textarea.dispatchEvent(new Event("input", { bubbles: true }));
      }
    }
  };

  return (
    <div className="flex flex-col w-full">
      {isLoading ? (
        <div className="flex justify-center items-center">
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        </div>
      ) : (
        <>
          <div className="absolute top-3 left-0 w-full -mt-2"></div>
          <div className="flex items-center justify-between">
            <TextField
              ref={inputRef}
              className="border-none w-full text-md focus:outline-none"
              multiline
              minRows={1}
              maxRows={8}
              onPaste={handlePaste}
              placeholder="Ask me anything..."
              onChange={handlePrompt}
              onKeyDown={handleKeyDown}
              autoComplete="off"
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "8px 8px 8px 16px",
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
            />
            <button
              className="soi-btn soi-btn-fab btn-primary shrink-0 my-2 me-2 self-end"
              type="button"
              onClick={handleSubmit}
            >
              <SioIcon name="submit" size={16} />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ChatInputField;
