/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, TextField } from "@mui/material";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import React, { useContext, useEffect, useRef, useState, memo } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import icon from "../../src/assets/response_icon.png";
import { bookmarkQuestions } from "../apis/recommendation/recommendation";
import Button from "../components/button";
import { devApis } from "../constants/constant";
import { getUserNames } from "../utils";
import { customFetch } from "../utils/RefreshTokenInterceptor";
import Charts from "./Charts";
import SioIcon from "./icons/IconLibrary";
import { DataContext } from "./layout/layout";
import TableContainer from "./TableContainer";
import FeedbackButtons from "./FeedbackButtons";

const MarkdownComponent = React.memo(
  ({
    markDownContainerData,
    session_id,
    filename,
    setUpdatedRecData,
    recId,
    hideFeedBackButtons,
    setAiChildRecommendations,
    currentFileName = "",
    isFromAskMePage = false,
    isInitialRec,
    setFilteredSuggestions,
    category,
    isShared = false,
    savedQuestion,
    parentComponent,
  }) => {
    const [isClicked, setIsClicked] = useState(false);
    const [index, setIndex] = useState(-1);
    const [savedStatusList, setSavedStatusList] = useState([]);
    const {
      setPromptData,
      setAPIData,
      setFilteredRec,
      setAMAEditQuestion,
      setEditSavedQuestion,
    } = useContext(DataContext);

    const Dataloading = () => {
      return (
        <div
          className={`flex shrink grow basis-0 w-[1%] items-center justify-center py-4`}
        >
          <svg width={0} height={0}>
            <defs>
              <linearGradient
                id="my_gradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor="#e01cd5" />
                <stop offset="100%" stopColor="#1CB5E0" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress
            size="32px"
            sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
          />
        </div>
      );
    };

    useEffect(() => {
      if (markDownContainerData) {
        setRecData(markDownContainerData);
      }

      markDownContainerData &&
        markDownContainerData.forEach(async (rec) => {
          if (rec?.input?.isSaved || rec?.input?.id) {
            setSavedStatusList((prevData) => [
              ...prevData,
              rec?.saved_status && rec?.id,
            ]);

            if (rec?.input?.isSaved) await bookmarkQuestions(rec?.input?.id);
          }
        });
    }, [markDownContainerData]);

    const MemoizedCharts = memo(Charts);

    const chartStatistics = (children, item) => {
      let formatedResponse = (data) => {
        if (typeof data === "string" && data.includes("\\")) {
          try {
            return <MathJax inline={false}>{`\\[${data}\\]`}</MathJax>;
          } catch (error) {
            console.error("MathJax rendering error:", error);
            return data;
          }
        } else {
          return item?.input ? data : data;
        }
      };
      const jsonRegex = /```json\s*({[\s\S]*?})\s*```/g;
      ///```json\s*([\s\S]*?)\s*```/g
      let chartDataArray = [];

      if (item?.response) {
        let match;
        while ((match = jsonRegex.exec(item.response)) !== null) {
          try {
            const parsedData = JSON.parse(match[1]);
            chartDataArray.push(parsedData);
          } catch (error) {
            console.error("Invalid JSON:", match[1], error);
          }
        }
      }

      return Array.isArray(children) ? (
        children.map((value) =>
          value?.type === "img" || value.type === "a" ? (
            chartDataArray.length > 0 &&
            value.props.href.includes("placeholder") ? (
              chartDataArray.map((chartData, ind) => (
                <div key={ind} className="sio-card-border !mt-3">
                  <MemoizedCharts chartData={chartData} />
                </div>
              ))
            ) : (
              <div className="flex flex-col gap-3">
                To improve performance, only a limited number of records are
                shown.
                <span>
                  <button
                    size="xs"
                    className="text-link-secondary font-semibold underline"
                    onClick={async () => {
                      const link = document.createElement("a");
                      link.href = value?.props?.href;

                      link.click();
                    }}
                  >
                    Please download to view the full data
                  </button>
                </span>
              </div>
            )
          ) : !value?.props &&
            value.includes("Chart Data") ? null : typeof value === "string" &&
            value
              .toLowerCase()
              .includes("[Download Data Breakdown]".toLowerCase()) ? (
            value.replace(/\[download data breakdown\]/gi, "")
          ) : (
            value
          )
        )
      ) : children?.type === "img" || children.type === "a" ? (
        chartDataArray.length > 0 &&
        children.props.href.includes("placeholder") ? (
          chartDataArray.map((chartData, ind) => (
            <div key={ind} className="sio-card-border !mt-3">
              <MemoizedCharts chartData={chartData} />
            </div>
          ))
        ) : (
          <div className="flex flex-col gap-3">
            To improve performance, only a limited number of records are shown.
            <span>
              <button
                size="xs"
                className="text-link-secondary font-semibold underline"
                onClick={async () => {
                  const link = document.createElement("a");
                  link.href = children?.props?.href;

                  link.click();
                }}
              >
                Please download to view the full data
              </button>
            </span>
          </div>
        )
      ) : !children?.props && children.includes("Chart Data") ? null : (
        formatedResponse(children)
      );
    };

    const [recData, setRecData] = useState(markDownContainerData);

    const editInputRef = useRef(null);

    const [editPrompt, setEditPrompt] = useState(null);

    const handleEditInputChange = (value) => {
      setEditPrompt(value);
    };

    const handleEditPrompt = (e) => {
      handleEditInputChange(e.target.value);
    };

    const editMessage = async (
      user_id,
      message_id,
      prompt,
      filename,
      recommendation_id,
      session_id
    ) => {
      try {
        const res = await customFetch(
          `${devApis.PYTHON_BASE_URL}/edit_message`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              message_id: message_id,
              new_content: prompt,
              user_id: user_id,
              filename: filename,
              recommendation_id: recommendation_id,
              session_id: session_id,
            }),
          }
        );

        if (res.status === 200 || res.status === 201) {
          const data = await res.json();
          setRecData((prevArray) => {
            const updatedArray = [...prevArray];
            updatedArray[updatedArray.length - 2] = {
              input: { content: prompt, id: data.message_id - 1 },
              id: data.message_id - 1,
            };
            updatedArray[updatedArray.length - 1] = {
              response: data.answer,
              id: data.message_id,
            };
            return updatedArray;
          });
          setUpdatedRecData((prevArray) => {
            const updatedArray = [...prevArray];
            updatedArray[updatedArray.length - 2] = {
              input: { content: prompt, id: data.message_id - 1 },
              id: data.message_id - 1,
            };
            updatedArray[updatedArray.length - 1] = {
              response: data.answer,
              id: data.message_id,
            };

            setAMAEditQuestion((prevData) => {
              return prevData.map((item) => {
                return item.payload.apiRecId === recommendation_id
                  ? { ...item, status: false }
                  : item;
              });
            });

            setAPIData((prevData) =>
              prevData.map((item) => {
                return item.payload.apiRecId === recommendation_id
                  ? { ...item, status: false }
                  : item;
              })
            );
            setEditSavedQuestion((prevData) =>
              prevData.map((item) => {
                return item.payload.apiRecId === recommendation_id && item
                  ? { ...item, status: false }
                  : item;
              })
            );
            return updatedArray;
          });
          setAiChildRecommendations([
            data?.qualitative_question,
            data?.quantitative_question,
          ]);
        }
      } catch (error) {
        setRecData((prevArray) => prevArray.slice(0, -2));
      }
    };

    const BouncingDots = () => {
      return (
        <div className="sio-loader-container">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
        </div>
      );
    };

    const mathJaxConfig = {
      loader: { load: ["input/tex", "output/chtml"] },
    };

    return (
      markDownContainerData &&
      markDownContainerData.length > 0 &&
      markDownContainerData?.map((item, ind) => (
        <div
          id={item?.input ? `inp${ind}` : `res${ind}`}
          className={`flex ${
            item?.input ? "items-end justify-end sio-salf" : "items-start"
          } relative`}
          key={ind}
        >
          {item?.LoadingData && <Dataloading />}
          {item?.response && (
            <img
              src={icon}
              alt="response_strived_icon"
              className={`md:p-2 md:py-3 bg-black rounded-full me-3 md:me-4 size-7 
                flex-shrink-0 md:size-10 ${ind === 0 && "invisible"}`}
            />
          )}
          <div className={`flex flex-col self-center overflow-hidden`}>
            {item?.response === "Loading..." ? (
              <BouncingDots />
            ) : (
              <MathJaxContext config={mathJaxConfig} onLoad={() => null}>
                <Markdown
                  remarkPlugins={[remarkGfm]}
                  className={`prose prose-sm sio-editor-content lg:prose-lg xl:prose-xl max-w-none
                      sm:text-[13px] md:text-[13px] 2xl:text-[16px] 3xl:text-[19px] flex-col ${
                        isClicked && index === ind && "hidden"
                      }`}
                  components={{
                    ul: ({ node, children, ...props }) => (
                      <ul
                        className="sio-list-disc sio-list-secondary"
                        {...props}
                      >
                        {children}
                      </ul>
                    ),
                    li: ({ node, children, ...props }) => (
                      <li {...props}>{chartStatistics(children, item)}</li>
                    ),
                    table: ({ node, children, ...props }) => (
                      <div>
                        <TableContainer>{children}</TableContainer>
                      </div>
                    ),
                    p: ({ node, children, ...props }) => (
                      <p
                        {...props}
                        style={{
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {Array.isArray(children) && children
                          ? children.map((child) =>
                              child?.props && child?.props?.children && null
                                ? child?.props?.children.includes(
                                    "Chart Data"
                                  ) && null
                                : child?.children &&
                                  child?.children.includes("Chart Data") &&
                                  null
                            )
                          : children?.children &&
                            children?.children.includes("Chart Data") &&
                            null}
                        {chartStatistics(children, item)}
                        {!isShared &&
                          item?.input &&
                          parentComponent !== "savedQuestions" && (
                            <button
                              className="ms-2 relative"
                              onClick={async () => {
                                const { saved_status } =
                                  await bookmarkQuestions(item?.input?.id);
                                setFilteredSuggestions &&
                                  setFilteredSuggestions((prev) => {
                                    const updatedData = prev.filter(
                                      (rec) => rec.id !== item?.input?.id
                                    );
                                    return updatedData;
                                  });
                                setFilteredRec((prev) => {
                                  const updatedData = prev.filter(
                                    (rec) => rec.id !== item?.input?.id
                                  );
                                  return updatedData;
                                });
                                if (!item?.input?.id) return;
                                saved_status
                                  ? setSavedStatusList((prevList) => [
                                      ...new Set([
                                        ...prevList,
                                        item?.input?.id,
                                      ]),
                                    ])
                                  : setSavedStatusList((prevList) => {
                                      return [
                                        ...new Set(
                                          prevList.filter(
                                            (id) => id !== item?.input?.id
                                          )
                                        ),
                                      ];
                                    });
                                setPromptData([saved_status]);
                              }}
                            >
                              {item?.input?.isSaved ||
                              savedStatusList.includes(item?.input?.id) ? (
                                <SioIcon name="saved" size={20} />
                              ) : (
                                <SioIcon name="save" size={20} />
                              )}
                            </button>
                          )}
                      </p>
                    ),
                    code: ({ node, children, ...props }) => null,
                  }}
                >
                  {item?.response ||
                    (item?.input?.content &&
                      item?.input?.content?.replace(/\n\n/g, "\n"))}
                </Markdown>
              </MathJaxContext>
            )}
            {!isShared && item?.input && !isClicked && (
              <Button
                variant="dark"
                size="xs"
                onClick={() => {
                  setIsClicked(true);
                  setIndex(ind);
                  setEditPrompt(item.input.content);
                  setTimeout(() => {
                    editInputRef.current.value = item.input;
                  }, 100);
                }}
                className="soi-btn-flat flex gap-1 items-center mt-1 self-end"
              >
                <SioIcon name="edit" size={14} />
                EDIT
              </Button>
            )}
            {isClicked && index === ind && (
              <div className="sio-chat-edit-col">
                <TextField
                  ref={editInputRef}
                  value={editPrompt}
                  multiline
                  minRows={1}
                  maxRows={6}
                  className="w-[100%] bg-transparent focus:outline-none mb-3"
                  id="prompt_data"
                  onChange={(e) => handleEditPrompt(e)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "0",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
                <div className="flex items-center justify-end gap-2">
                  <Button
                    variant="dark"
                    size="xs"
                    className=""
                    onClick={() => setIsClicked(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    size="xs"
                    className=""
                    onClick={async () => {
                      const { data } = await getUserNames();
                      setIsClicked(false);

                      if (
                        item.input.content === editPrompt ||
                        editPrompt === ""
                      ) {
                        setEditPrompt(item.input.content);
                        return;
                      }

                      setAPIData((prev) => [
                        ...prev,
                        {
                          status: true,
                          apiCallname: "Edit",
                          questionIndex: ind,
                          payload: {
                            apiPrompt: editPrompt,
                            apiSessionId: session_id,
                          },
                        },
                      ]);

                      setAMAEditQuestion((prev) => [
                        ...prev,
                        {
                          status: true,
                          apiCallname: "Edit",
                          questionIndex: ind,
                          payload: {
                            apiPrompt: editPrompt,
                            apiSessionId: session_id,
                          },
                          index: ind,
                        },
                      ]);

                      setEditSavedQuestion((prev) => [
                        ...prev,
                        {
                          status: true,
                          apiCallname: "Edit",
                          questionIndex: ind,
                          payload: {
                            apiPrompt: editPrompt,
                            apiSessionId: session_id,
                          },
                          index: ind,
                        },
                      ]);

                      setUpdatedRecData((prevArray) => {
                        const updatedArray = [
                          ...prevArray.slice(0, ind),
                          { input: { content: editPrompt, id: null } },
                          { response: "Loading..." },
                        ];
                        return updatedArray;
                      });

                      await editMessage(
                        data?.user?.id,
                        item.id || item?.message_id,
                        editPrompt,
                        filename,
                        recId?.current,
                        item?.session_id
                      );
                    }}
                  >
                    Send
                  </Button>
                </div>
              </div>
            )}
            <FeedbackButtons
              item={item}
              ind={ind}
              setRecData={setRecData}
              setFilteredSuggestions={setFilteredSuggestions}
              isShared={isShared}
              session_id={session_id}
              setUpdatedRecData={setUpdatedRecData}
              hideFeedBackButtons={hideFeedBackButtons}
              markDownContainerData={markDownContainerData}
              recData={recData}
              isInitialRec={isInitialRec}
              currentFileName={currentFileName}
              isFromAskMePage={isFromAskMePage}
              category={category}
              savedQuestion={savedQuestion}
              filename={filename}
            />
          </div>
        </div>
      ))
    );
  }
);

export default MarkdownComponent;
