/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import signupLogo from "../../assets/logo.svg";
import { TextField } from "@mui/material";
import Button from "../../components/button";
import {
  postSignup,
  getIpAddress,
  postObserverSignup,
  postEmployeeSignup,
  postManagerSignup,
  getTokenStatus,
  updateTokenStatus,
} from "../../apis/signup/index";
import { ThemeProvider } from "@mui/material/styles";
import { darkTheme } from "../../theme";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decodeToken } from "../../utils";
import SioIcon from "../../components/icons/IconLibrary";
import TimezoneSelect1 from "../../components/TimezoneSelect";

const Signup = () => {
  const [selectedTimezone, setSelectedTimezone] = useState(
    "America/Los_Angeles"
  );
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("Admin!23");
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [userName, setUserName] = useState("");
  const [usernameError, setUsernameError] = useState("");

  const [organisation, setOrganisation] = useState("");
  const [emailToken, setEmailToken] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [inviteType, setInviteType] = useState("");

  useEffect(() => {
    (async () => {
      const isAddressData = await getIpAddress();
      if (isAddressData?.status === 200) {
        const isAddressResp = isAddressData?.data?.ip || "";
        setIpAddress(isAddressResp);
      } else {
        setIpAddress("");
      }
    })();
  }, []);

  const handleTimezoneChange = (timezone) => {
    setSelectedTimezone(timezone.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get("token");
      const type = params.get("type");
      const decodedData = decodeToken(token);
      const token_email = decodedData?.user_email;
      const token_org = decodedData?.org_name;
      setEmail(token_email);
      setOrganisation(token_org);
      try {
        const tokenStatusResp = await getTokenStatus({
          email: token_email,
          org_name: token_org,
        });

        if (tokenStatusResp?.status === 200) {
          const tokenStatus = tokenStatusResp?.data?.status;
          if (tokenStatus === true) {
            toast.error(
              "Token is already used or revoked. Redirecting to login."
            );
            navigate("/login");
          }
        }
      } catch (error) {
        // toast.error("Failed to validate token.")
      }
      setInviteType(type);

      if (token && token.length > 0) {
        setEmailToken(token);
      } else {
        navigate("/login");
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [location]);

  const handleEmailProceed = async () => {};

  const handleButtonClick = async () => {
    if (firstNameError || lastNameError || usernameError) {
      toast.error("Please correct the highlighted errors before proceeding.", {
        theme: "light",
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      return;
    }
    if (!firstName) {
      toast.error("First name is required", {
        theme: "light",
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      return;
    }
    if (!lastName) {
      toast.error("Last name is required", {
        theme: "light",
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      return;
    }
    if (!userName) {
      toast.error("Username is required", {
        theme: "light",
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      return;
    }
    const basePayload = {
      token: emailToken,
      appId: 50,
      first_name: firstName,
      last_name: lastName,
      username: userName,
      phone: "",
      password: password,
      time_zone: selectedTimezone,
      ip_address: ipAddress,
    };

    let payload = { ...basePayload };
    let registerFunction;

    switch (inviteType) {
      case "observer":
        registerFunction = postObserverSignup;
        break;

      case "district-admin":
      case "teacher":
      case "site-admin":
      case "school-admin":
        registerFunction = postEmployeeSignup;
        break;

      case "orgAdmin":
        payload = {
          ...basePayload,
          org_name: organisation,
          subcription: "Starter",
        };
        registerFunction = postSignup;
        break;

      case "manager":
        registerFunction = postManagerSignup;
        break;

      default:
        console.log("Invalid invite type");
        return;
    }

    try {
      const registerFunctionResp = await registerFunction(payload);

      if (registerFunctionResp?.status === 200) {
        // const status = registerFunctionResp?.data?.status;

        toast.success("Successfully registered", {
          theme: "light",
          icon: <SioIcon name="check" size={16} color="blue" />,
        });
        await updateTokenStatus({
          email: email,
          org_name: organisation,
        });
        navigate("/login");
      } else {
        toast.error("Failed to sign up");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.message);
    }

    // navigate("/login");
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="bg-dark min-h-dvh flex flex-col xs:p-2">
        <div className="sio-col-460 mb-auto">
          <div className="text-center px-12 pb-8 flex flex-col justify-center">
            <img
              src={signupLogo}
              alt="signup-page-logo"
              className="mt-12 mb-3 xs:h-9"
            />
            <p className="text-secondary-light text-xs sm:text-sm">
              Strived combines student data with district goals to deliver
              classroom insights
            </p>
          </div>
          <div className="sio-card bg-white/5 rounded-xl">
            <div className="text-center">
              <h1>Complete your account registration</h1>
              <p className="text-secondary-light text-xs sm:text-sm">
                Please share your details below{" "}
              </p>
            </div>
            <div className="grid grid-cols-1 gap-x-6 gap-y-6">
              <div className="grid grid-cols-2 gap-x-2 sm:gap-x-3 gap-y-2">
                <TextField
                  label="First Name"
                  name="firstName"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    const regex = /^[a-zA-Z']+(?: [a-zA-Z']+)*$/;
                    if (!regex.test(e.target.value)) {
                      setFirstNameError(
                        "Only letters, apostrophes, and single spaces allowed."
                      );
                    } else {
                      setFirstNameError("");
                    }
                  }}
                  value={firstName}
                  validationRule={/^[a-zA-Z']+(?: [a-zA-Z']+)*$/}
                  errorMessage="Only letters, apostrophes, and single spaces allowed."
                  error={!!firstNameError}
                  helperText={firstNameError}
                  fullWidth
                />
                <TextField
                  label="Last Name"
                  name="lastName"
                  onChange={(e) => {
                    setLastName(e.target.value);
                    const regex = /^[a-zA-Z']+(?: [a-zA-Z']+)*$/;
                    if (!regex.test(e.target.value)) {
                      setLastNameError(
                        "Only letters, apostrophes, and single spaces allowed."
                      );
                    } else {
                      setLastNameError("");
                    }
                  }}
                  value={lastName}
                  error={!!lastNameError}
                  helperText={lastNameError}
                  fullWidth
                />
              </div>
              <TextField
                label="Username"
                name="userName"
                onChange={(e) => {
                  setUserName(e.target.value);
                  const regex = /^[a-zA-Z0-9_]{6,15}$/;
                  if (!regex.test(e.target.value)) {
                    setUsernameError(
                      "Username should be 6-15 characters, letters, numbers, and underscores only."
                    );
                  } else {
                    setUsernameError("");
                  }
                }}
                value={userName}
                inputProps={{ maxLength: 15 }}
                error={!!usernameError}
                helperText={usernameError}
                fullWidth
              />

              <TimezoneSelect1
                selectedTimezone={selectedTimezone}
                handleTimezoneChange={handleTimezoneChange}
              />
            </div>
            <Button onClick={handleButtonClick} variant="secondary">
              Register{" "}
            </Button>

            <p className="text-secondary-light text-xs sm:text-sm text-center">
              Already have an account? 
              <a className="sio-link-secondary" href="/login">
                {" "}
                Sign In
              </a>
            </p>
          </div>
        </div>

        <footer className="flex justify-center p-3">
          <ul className="flex divide-x text-sm divide-light font-medium leading-none flex-wrap justify-center">
            <li className="px-2 xs:my-1 sm:my-0">
              <a
                className="text-muted-light hover:text-[color:#cf037e]"
                href="/">
                {" "}
                Terms of Use
              </a>
            </li>
            <li className="px-2 xs:my-1 sm:my-0">
              <a
                className="text-muted-light hover:text-[color:#cf037e]"
                href="/">
                Privacy Policy
              </a>
            </li>
            <li className="px-2 xs:my-1 sm:my-0 text-muted-light xs:border-0 md:border-1">
              ©{new Date().getFullYear()} Strived.io, All Rights Reserved
            </li>
          </ul>
        </footer>
      </div>
    </ThemeProvider>
  );
};

export default Signup;
