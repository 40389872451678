/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, Dialog, TextField, Tooltip } from "@mui/material";
import { debounce } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addMessageToSession,
  bookmarkAiMessage,
  bookmarkQuestions,
  getBookmarkedMessages,
  getChatHistory,
  getSavedPromptResponse,
  getSavedQuestions,
  saveAiChildRecommendations,
  startConversation,
} from "../../../apis/recommendation/recommendation";
import Button from "../../../components/button";
import SioIcon from "../../../components/icons/IconLibrary";
import { DataContext } from "../../../components/layout/layout";
import MarkdownComponent from "../../../components/MarkdownComponent";
import SioTooltipClampedText from "../../../components/TooltipClampedText";
import { getUserNames } from "../../../utils";
import { checkAndRefreshToken } from "../../../utils/authUtils";

const SavedQuestionsContainer = ({
  recommendationData,
  isPromptEdited,
  editedPromptData,
  savedQuestionsData,
  setSavedQuestionsData,
  suggestionsData,
  setSelectedIndex,
  setFilteredRec,
  selectedIndex,
}) => {
  const {
    setUpdatedQuestionsData,
    setPromptData,
    newlyAddedQuestion,
    refreshAPIData,
    refreshSavedQuestion,
    setAddSavedQuestion,
    addSavedQuestion,
    editSavedQuestion,
    savedQuestionsRecData,
    setSavedQuestionsRecData,
    setVisitedSavedQuestions,
    aiPromptResponse,
    setAiPromptResponse,
  } = useContext(DataContext);

  const recId = useRef(recommendationData?.id);
  const divRef = useRef(null);
  // const [updateSessionId, setUpdateSessionId] = useState(null);
  const updateSessionId = useRef(recommendationData?.session_id);
  const [aiChildRecommendations, setAiChildRecommendations] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isQuestionResponseLoaading, setIsQuestionResponseLoaading] =
    useState(false);
  const [clickedQuestion, setClickedQuestion] = useState(null);

  const navigate = useNavigate();

  const getSavedQuestionResponse = async (recId, savedQuestionsRecData) => {
    const token = await checkAndRefreshToken();
    if (!token) navigate("/login");
    const prompt = new URLSearchParams();
    setIsQuestionResponseLoaading(true);

    let responseObject = [];
    let promptResponse;

    if (
      savedQuestionsRecData?.type &&
      (savedQuestionsRecData?.type.startsWith("recommendation") ||
        savedQuestionsRecData?.type === "qualitative_question" ||
        savedQuestionsRecData?.type === "quantitative_question")
    ) {
      let session_id;

      if (
        aiPromptResponse.filter(
          (response) =>
            response.content ===
              (savedQuestionsData?.content || recommendationData?.content) &&
            response.status
        ).length === 0
      ) {
        setAiPromptResponse((prevRes) => {
          return [
            ...prevRes,
            {
              content:
                savedQuestionsData?.content || recommendationData?.content,
              type: savedQuestionsData?.type || recommendationData?.type,
              message_id: savedQuestionsData?.id || recommendationData?.id,
              status: true,
            },
          ];
        });

        const data = await startConversation(0, token);
        session_id = data.session_id;

        const data2 = await addMessageToSession(
          session_id,
          savedQuestionsRecData?.content,
          recommendationData.filename,
          "yes",
          null
        );

        promptResponse = data2?.user_question;

        setAiPromptResponse((prevRes) => {
          return prevRes.map((res) => {
            return res.content === promptResponse
              ? { ...res, status: false }
              : res;
          });
        });

        const { saved_messages } = await getSavedQuestions();

        setFilteredRec((prev) => {
          const getSavedQuestion = saved_messages.filter(
            (message) =>
              message.content === promptResponse && message.type === "human"
          );

          setTimeout(() => {
            if (
              saved_messages.filter(
                (message) =>
                  message.content === promptResponse && message.type !== "human"
              ).length > 0
            ) {
              saveAiChildRecommendations(
                [savedQuestionsRecData],
                savedQuestionsRecData?.type === "qualitative_question" ||
                  savedQuestionsRecData?.type === "quantitative_question"
                  ? savedQuestionsRecData?.id
                  : recommendationData?.recommendation_id,
                null,
                savedQuestionsRecData?.type
              );
            }
          }, 300);

          if (getSavedQuestion.length > 0 && prev) {
            return prev.map((prevData) => {
              return prevData.content === promptResponse
                ? {
                    ...prevData,
                    type: "human",
                    id: getSavedQuestion[0].id,
                    session_id: getSavedQuestion[0].session_id,
                  }
                : prevData;
            });
          }
        });

        setVisitedSavedQuestions((prev) =>
          prev.filter((data) => data !== promptResponse)
        );

        prompt.append("message_id", data2.message_id - 1);
        const { answer } = await getSavedPromptResponse(prompt);

        setAiChildRecommendations([
          answer?.qualitative_question,
          answer?.quantitative_question,
        ]);

        responseObject.push({
          response: answer?.content,
          id: answer?.id,
          likes: answer?.likes,
          disLikes: answer?.dislikes,
          created_at: answer?.created_at,
        });

        if (data2?.user_question === selectedIndex?.content) {
          setSavedQuestionsRecData(() => {
            return responseObject;
          });
          setSelectedIndex({
            id: data2.message_id - 1,
            content: promptResponse,
          });
          const newUrl = `${window.location.pathname}?messageId=${
            data2?.message_id - 1
          }`;
          window.history.pushState({ path: newUrl }, "", newUrl);
        }
      } else {
        setSavedQuestionsRecData(null);
      }
    } else {
      prompt.append("message_id", recId);

      if (recId) {
        const { answer } = await getSavedPromptResponse(prompt);

        const nextQueryParams = new URLSearchParams();
        nextQueryParams.append("session_id", updateSessionId.current);
        nextQueryParams.append("page", 1);
        nextQueryParams.append("per_page", 10);

        const { data } = await getUserNames();
        nextQueryParams.append("user_id", data?.user?.id);

        nextQueryParams.append("user_id", data?.user?.id);
        const historyData = await getChatHistory(nextQueryParams);

        setAiChildRecommendations([
          answer?.qualitative_question,
          answer?.quantitative_question,
        ]);

        responseObject.push({
          response: answer?.content,
          id: answer?.id,
          likes: answer?.likes,
          disLikes: answer?.dislikes,
          created_at: answer?.created_at,
          session_id: answer?.session_id,
        });

        historyData.history.reverse().forEach((data, ind) => {
          if (data.type === "ai") {
            responseObject.push({
              response: data.content,
              id: data.id,
              likes: data.likes,
              disLikes: data.dislikes,
              created_at: data.created_at,
              session_id: data.session_id,
            });
          } else {
            responseObject.push({
              input: { content: data.content, id: data.id },
              id: data.id,
              likes: data.likes,
              disLikes: data.dislikes,
              created_at: data.created_at,
              session_id: data.session_id,
            });
          }
        });
        if (
          refreshSavedQuestion.filter(
            (data) =>
              data.payload.apiSessionId === updateSessionId.current &&
              data.status
          ).length > 0
        ) {
          const getIndex = refreshSavedQuestion.filter(
            (data) => data.payload.apiSessionId === updateSessionId.current
          )[0].index;

          responseObject.splice(getIndex, responseObject.length, {
            response: "Loading...",
          });
        } else if (
          addSavedQuestion.filter(
            (data) =>
              data.payload.apiSessionId === updateSessionId.current &&
              data.status
          ).length > 0
        ) {
          responseObject.push(
            {
              input: {
                content: addSavedQuestion.filter(
                  (data) =>
                    data.payload.apiSessionId === updateSessionId.current &&
                    data.status
                )[0].payload.apiPrompt,
              },
            },
            { response: "Loading..." }
          );
        } else if (
          editSavedQuestion.filter(
            (data) =>
              data.payload.apiSessionId === updateSessionId.current &&
              data.status
          ).length > 0
        ) {
          const getIndex = editSavedQuestion.filter(
            (data) =>
              data.payload.apiSessionId === updateSessionId.current &&
              data.status
          )[0].index;
          responseObject.splice(getIndex + 1, responseObject.length, {
            response: "Loading...",
          });
        }
        // updateSessionId.current = answer?.session_id;
      }

      setSavedQuestionsRecData(() => responseObject);
    }

    setIsQuestionResponseLoaading(false);
  };

  useEffect(() => {
    if (!recommendationData) return;
    updateSessionId.current = recommendationData.session_id;
    setSavedQuestionsRecData([]);
    setSavedQuestionsData(null);
    if (editedPromptData) {
      setAiChildRecommendations([
        editedPromptData?.qualitative_question,
        editedPromptData?.quantitative_question,
      ]);
      const responseObject = {
        response: editedPromptData?.answer,
        id: editedPromptData?.id,
        likes: editedPromptData?.likes,
        disLikes: editedPromptData?.dislikes,
        created_at: editedPromptData?.created_at,
      };
      setSavedQuestionsRecData([responseObject]);
      updateSessionId.current = editedPromptData?.new_session_id;
    } else {
      // if (
      //   refreshSavedQuestion &&
      //   refreshSavedQuestion.filter(
      //     (data) =>
      //       data.payload.apiSessionId === updateSessionId.current && data.status
      //   ).length > 0
      // ) {
      //   const getIndex = refreshSavedQuestion.filter(
      //     (data) => data.payload.apiSessionId === updateSessionId.current
      //   )[0].index;
      //   // setSavedQuestionsRecData((prevArray) => {
      //   //   const updatedArray = [{ response: "Loading..." }];
      //   //   return updatedArray;
      //   // });
      //   return;
      // }
      getSavedQuestionResponse(recommendationData?.id, recommendationData);
    }
  }, [recommendationData, editedPromptData, refreshAPIData]);

  const computeDateTime = (updated) => {
    let hours = updated.getHours();
    let month = updated.toLocaleString("default", { month: "short" });
    let date = updated.getDate();
    let year = updated.getFullYear();

    const minutes = updated.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    const time = `${date} ${month} ${year}, ${hours}:${formattedMinutes} ${ampm}`;
    return time;
  };

  const calculateTime = (date) => {
    if (!date) return null;
    const today = new Date();
    const updated = new Date(date);
    const updatedMonth = updated.toLocaleString("default", { month: "short" });
    const updatedDate = updated.getDate();
    const todayMonth = today.toLocaleString("default", { month: "short" });
    const todayDate = today.getDate();

    let recommendationDate;
    if (todayMonth === updatedMonth) {
      if (todayDate - updatedDate >= 1) {
        recommendationDate = `${computeDateTime(updated)}`;
      } else if (todayDate - updatedDate === 0) {
        recommendationDate = computeDateTime(updated);
      }
    } else {
      recommendationDate = computeDateTime(updated);
    }
    return recommendationDate;
  };

  const [prompt, setPrompt] = useState(null);

  const { setDataFromChildren } = useContext(DataContext);
  const inputRef = useRef(null);

  const [loadingPromptResponse, setLoadingPromptResponse] = useState(false);

  const handlePromptValue = (value) => {
    setDataFromChildren(value);
  };

  const clearPrompt = () => {
    document.getElementById("prompt_data").value = "";
  };

  const handleInputChange = useCallback(
    debounce((value) => {
      setPrompt(value);
    }, 500),
    []
  );

  const handlePrompt = (e) => {
    handleInputChange(e.target.value);
  };

  const fileUploadRequest = async () => {
    setLoadingPromptResponse(true);
    const promptText = document.getElementById("prompt_data").value;
    clearPrompt();

    try {
      setAddSavedQuestion((prev) => {
        return [
          ...prev,
          {
            apiCallname: "Add",
            payload: {
              apiSessionId: updateSessionId.current,
              apiRecId: setSavedQuestionsRecData[0]?.id,
              apiPrompt: promptText,
            },
            status: true,
          },
        ];
      });
      setIsLoading(true);
      setSavedQuestionsRecData((prevArray) => {
        const updatedArray = [
          ...prevArray,
          {
            input: {
              content: promptText,
              id: null,
            },
          },
          { response: "Loading..." },
        ];
        return updatedArray;
      });

      const token = await checkAndRefreshToken();
      if (!token) navigate("/login");

      const promptValue = promptText;

      if (!updateSessionId.current) {
        const data = await startConversation(recId.current, token);
        updateSessionId.current = data.session_id;

        const data2 = await addMessageToSession(
          data.session_id,
          promptValue,
          recommendationData.filename
        );

        setSavedQuestionsRecData((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[updatedArray.length - 1] = {
            response: data2?.answer,
          };
          return updatedArray;
        });

        if (
          data2 &&
          data2?.qualitative_question !== null &&
          data2.quantitative_question !== null
        ) {
          const aiRec = [
            data2?.qualitative_question,
            data2?.quantitative_question,
            data2?.qualitative_saved,
            data2?.quantitative_saved,
          ];
          setAiChildRecommendations(aiRec);
        }
      } else {
        const data2 = await addMessageToSession(
          updateSessionId.current,
          promptValue,
          recommendationData.filename
        );
        setSavedQuestionsRecData((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[updatedArray.length - 1] = {
            response: data2?.answer,
          };
          setAddSavedQuestion((prevData) => {
            const filteredData = prevData.map((data) => {
              return data.payload.apiPrompt === data2?.user_question
                ? { ...data, status: false }
                : data;
            });
            return filteredData;
          });
          return updatedArray;
        });

        if (
          data2 &&
          data2?.qualitative_question !== null &&
          data2.quantitative_question !== null
        ) {
          const aiRec = [
            data2?.qualitative_question,
            data2?.quantitative_question,
            data2?.qualitative_saved,
            data2?.quantitative_saved,
          ];
          setAiChildRecommendations(aiRec);
        }
      }
      setLoadingPromptResponse(false);

      setPrompt("");
      setIsLoading(false);
    } catch (error) {
      setSavedQuestionsRecData((prevArray) => prevArray.slice(0, -2));
      toast.error("Error:", error, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      return null;
    }
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      if (
        refreshSavedQuestion &&
        (refreshSavedQuestion.filter(
          (data) =>
            data.payload.apiSessionId === updateSessionId.current && data.status
        ).length > 0 ||
          editSavedQuestion.filter(
            (data) =>
              data.payload.apiSessionId === updateSessionId.current &&
              data.status
          ).length > 0 ||
          addSavedQuestion.filter(
            (data) =>
              data.payload.apiSessionId === updateSessionId.current &&
              data.status
          ).length > 0)
      )
        return;
      e.preventDefault();

      const input = e.target.value;
      if (!input || input.trim().length === 0) {
        toast.error("Please provide input", {
          icon: <SioIcon name="close" size={16} color="blue" />,
        });
        return;
      }
      await fileUploadRequest();
    } else if (e.key === "Enter" && e.shiftKey) {
      return;
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    setPrompt("");

    const clipboardText = event.clipboardData.getData("text");
    const cleanedText = clipboardText
      .split("\n")
      .filter((line) => line.trim() !== "")
      .join("\n")
      .trim();

    if (inputRef.current) {
      const textarea = inputRef.current.querySelector("textarea");
      if (textarea) {
        const { selectionStart, selectionEnd } = textarea;

        const currentValue = prompt || "";

        const newValue =
          currentValue.substring(0, selectionStart) +
          cleanedText +
          currentValue.substring(selectionEnd);

        setPrompt(newValue);

        textarea.value = newValue;

        textarea.dispatchEvent(new Event("input", { bubbles: true }));
      }
    }
  };

  useEffect(() => {
    const scrollToResponse = () => {
      if (divRef.current) {
        const container = divRef.current;
        const lastDiv = container.lastElementChild.lastElementChild;
        if (lastDiv) {
          lastDiv.scrollIntoView({ behavior: "smooth", block: "start" });
          const scrollPosition =
            lastDiv.offsetTop +
            lastDiv.offsetHeight -
            lastDiv.getBoundingClientRect().height -
            container.offsetHeight;

          container.scrollTo({
            top: scrollPosition - 40,
            behavior: "smooth",
          });
        }
      }
    };
    scrollToResponse();
  }, [savedQuestionsRecData]);

  const getConversationsResponse = async (value) => {
    try {
      setAddSavedQuestion((prev) => {
        return [
          ...prev,
          {
            apiCallname: "Add",
            payload: {
              apiSessionId: updateSessionId.current,
              apiRecId: savedQuestionsRecData[0]?.id,
              apiPrompt: value,
            },
            status: true,
          },
        ];
      });
      setLoadingPromptResponse(true);
      const token = await checkAndRefreshToken();
      if (!token) navigate("/login");

      if (!updateSessionId.current) {
        const data = await startConversation(recId.current, token);
        const data2 = await addMessageToSession(
          data.session_id,
          value,
          recommendationData.filename
        );

        setSavedQuestionsRecData((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[updatedArray.length - 1] = {
            response: data2?.answer,
          };

          return updatedArray;
        });
        if (
          data2 &&
          data2?.qualitative_question !== null &&
          data2.quantitative_question !== null
        ) {
          const aiRec = [
            data2?.qualitative_question,
            data2?.quantitative_question,
            data2?.qualitative_saved,
            data2?.quantitative_saved,
          ];
          setAiChildRecommendations(aiRec);
        }
        updateSessionId.current = data.session_id;
      } else {
        const data2 = await addMessageToSession(
          updateSessionId.current,
          value,
          recommendationData.filename
        );

        setSavedQuestionsRecData((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[updatedArray.length - 1] = {
            response: data2?.answer,
          };

          setAddSavedQuestion((prevData) => {
            const filteredData = prevData.map((data) => {
              return data.payload.apiPrompt === data2?.user_question
                ? { ...data, status: false }
                : data;
            });
            return filteredData;
          });
          return updatedArray;
        });

        if (
          data2 &&
          data2?.qualitative_question !== null &&
          data2.quantitative_question !== null
        ) {
          const aiRec = [
            data2?.qualitative_question,
            data2?.quantitative_question,
            data2?.qualitative_saved,
            data2?.quantitative_saved,
          ];
          setAiChildRecommendations(aiRec);
        }
        const date = new Date();
        const updatedData = { content: value };
        updatedData.created_at = date.toUTCString();
        updatedData.saved_status = false;
        handlePromptValue(updatedData);
      }
      setLoadingPromptResponse(false);

      setPrompt("");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setSavedQuestionsRecData((prevArray) => prevArray.slice(0, -2));
      toast.error("Error:", error, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      return null;
    }
  };

  const [openRemoveModal, setRemoveModalOpen] = useState(false);

  const handleRemoveModalClickOpen = (item) => {
    setRemoveModalOpen(true);
  };

  const handleRemoveModalClose = () => {
    setRemoveModalOpen(false);
  };

  const handleRemoveQuestion = async () => {
    try {
      if (
        [
          "quantitative_question",
          "qualitative_question",
          "recommendation_qualitative",
          "recommendation_quantitative",
        ].includes(clickedQuestion?.type)
      ) {
        await saveAiChildRecommendations(
          [clickedQuestion],
          clickedQuestion?.type === "qualitative_question" ||
            clickedQuestion?.type === "quantitative_question"
            ? clickedQuestion?.id
            : recommendationData?.recommendation_id,
          null,
          clickedQuestion?.type
        );

        const { bookmarked_messages } = await getBookmarkedMessages();
        if (bookmarked_messages.length > 0) {
          const bookmarkedMessageId = bookmarked_messages.filter(
            (bookmarked) => {
              return bookmarked.content === recommendationData.content;
            }
          )[0];

          await bookmarkAiMessage(
            bookmarkedMessageId.id,
            bookmarkedMessageId.type.includes("quantitative")
              ? "quantitative_question"
              : "qualitative_question",
            bookmarkedMessageId.type.includes("recommendation")
              ? "recommendation_id"
              : "message_id"
          );
        }
      } else {
        await bookmarkQuestions(recommendationData?.id);
        const { bookmarked_messages } = await getBookmarkedMessages();

        if (bookmarked_messages.length > 0) {
          const bookmarkedMessageId = bookmarked_messages.filter(
            (bookmarked) => {
              return bookmarked.content === recommendationData.content;
            }
          )[0];

          if (bookmarkedMessageId) {
            await bookmarkAiMessage(
              bookmarkedMessageId?.id,
              bookmarkedMessageId.type.includes("quantitative")
                ? "quantitative_question"
                : "qualitative_question",
              bookmarkedMessageId.type.includes("recommendation")
                ? "recommendation_id"
                : "message_id"
            );
          }
        }

        setFilteredRec((prevData) => {
          const updatedData = prevData.filter(
            (item) => item.id !== recommendationData?.id
          );

          const index = prevData.findIndex(
            (item) => item.id === recommendationData?.id
          );
          if (updatedData.length === 0) {
            const newUrl = `${window.location.pathname}`;
            window.history.pushState({ path: newUrl }, "", newUrl);
          } else {
            if (index === updatedData.length) {
              const newUrl = `${window.location.pathname}?messageId=${
                updatedData[index - 1]?.id
              }`;
              window.history.pushState({ path: newUrl }, "", newUrl);
            } else {
              const newUrl = `${window.location.pathname}?messageId=${updatedData[index]?.id}`;
              window.history.pushState({ path: newUrl }, "", newUrl);
            }
          }
          return updatedData;
        });
      }
      setRemoveModalOpen(false);
      // setSelectedIndex(0);
      toast.success("Question has been removed from saved successfully", {
        icon: <SioIcon name="check" size={16} color="blue" />,
      });
    } catch (err) {
      console.error(err);
      toast.error("Error:", err, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
    }
  };

  return (
    <div className={`flex shrink grow  basis-0 w-[1%] flex-col`}>
      {isQuestionResponseLoaading ||
      isPromptEdited.filter((data) => {
        return data.id === recommendationData?.id && data.status === true;
      }).length > 0 ||
      !recommendationData ||
      newlyAddedQuestion.includes(
        savedQuestionsData?.content || recommendationData?.content
      ) ||
      !savedQuestionsRecData ? (
        <div
          className="flex flex-col justify-start items-center gap-10 text-md 
          font-semibold w-full h-full pt-20"
        >
          <svg width={0} height={0}>
            <defs>
              <linearGradient
                id="my_gradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor="#e01cd5" />
                <stop offset="100%" stopColor="#1CB5E0" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress
            sx={{
              "svg circle": { stroke: "url(#my_gradient)" },
              size: "20px",
            }}
          />
          {<h2>Please wait while we generate the response.</h2>}
        </div>
      ) : (
        <>
          <div className="flex justify-between items-md-center p-3 md:p-7 md:pb-3">
            <div className="flex gap-3 md:gap-4 items-md-center">
              {/* <i className="sio-circle-icon bg-warning/20 size-7 md:size-10 shrink-0">
                <SioIcon name="saved" className="size-4 md:size-6" />
              </i> */}
              <div>
                <h2 className="font-semibold text-base md:text-xl text-primary-dark mb-1">
                  {savedQuestionsData?.content || recommendationData?.content}
                </h2>
                <ul className="flex divide-x text-xs md:text-sm divide-dark leading-none flex-wrap">
                  <li className="my-0 text-muted-dark mr-2">
                    Saved on{" "}
                    {calculateTime(
                      recommendationData?.created_at ||
                        savedQuestionsData?.created_at
                    )}
                  </li>
                  <Tooltip
                    title={
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {savedQuestionsData?.filename ||
                          recommendationData?.filename.map((name, index) => (
                            <span key={index}>
                              {name}
                              {index !==
                                (
                                  savedQuestionsData?.filename ||
                                  recommendationData?.filename
                                ).length -
                                  1 && ","}
                            </span>
                          ))}
                      </div>
                    }
                    componentsProps={{
                      tooltip: {
                        sx: {
                          maxHeight: "300px",
                          overflowY: "auto",
                          pointerEvents: "auto",
                          fontSize: "12px",
                          padding: "8px 12px",

                          "& .MuiTooltip-tooltip": {
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                          },
                          "&::-webkit-scrollbar": {
                            width: "2px",
                          },
                          "&::-webkit-scrollbar-track": {
                            borderRadius: "8px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            borderRadius: "8px",
                            "&:hover": {
                              backgroundColor: "#666",
                            },
                          },
                        },
                      },
                    }}
                    interactive
                  >
                    <li
                      className="px-0 ps-2 xs:my-1 sm:my-0 text-muted-dark hidden md:block 
                      w-[13rem] truncate"
                    >
                      {(savedQuestionsData?.filename &&
                        savedQuestionsData?.filename.join(", ")) ||
                        (recommendationData?.filename &&
                          recommendationData?.filename.join(", "))}
                    </li>
                  </Tooltip>
                </ul>
              </div>
            </div>
            <div className="flex gap-1 md:gap-3">
              <Tooltip title="Remove from Saved">
                <Button
                  outline
                  fab
                  variant="dark"
                  onClick={async () => {
                    handleRemoveModalClickOpen(recommendationData?.id);
                    setClickedQuestion(recommendationData);
                  }}
                >
                  <SioIcon name="saved" size={18} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <div
            id="container"
            ref={divRef}
            className={`overflow-y-scroll scrollbar-none p-3 md:p-7 pt-0`}
          >
            <div className={`flex flex-col`}>
              {
                <MarkdownComponent
                  markDownContainerData={savedQuestionsRecData}
                  loadingPromptResponse={loadingPromptResponse}
                  session_id={updateSessionId.current}
                  filename={recommendationData.filename}
                  setUpdatedRecData={setSavedQuestionsRecData}
                  currentFileName={
                    savedQuestionsData?.content || recommendationData?.content
                  }
                  savedQuestion={
                    savedQuestionsData?.content || recommendationData?.content
                  }
                  parentComponent={"savedQuestions"}
                />
              }
            </div>
          </div>
          <div className="flex flex-col rounded-t-lg shadow-inputField py-3 mt-auto">
            {aiChildRecommendations && aiChildRecommendations.length > 0 && (
              <div className="flex justify-around text-xs gap-2 md:gap-5 mx-2 md:mx-7 mb-2 md:mb-5">
                {refreshSavedQuestion.filter(
                  (data) =>
                    data.payload.apiSessionId === updateSessionId.current &&
                    data.status
                ).length === 0 &&
                  addSavedQuestion.filter(
                    (data) =>
                      data.payload.apiSessionId === updateSessionId.current &&
                      data.status
                  ).length === 0 &&
                  editSavedQuestion.filter(
                    (data) =>
                      data.payload.apiSessionId === updateSessionId.current &&
                      data.status
                  ).length === 0 &&
                  aiChildRecommendations?.map(
                    (value, index) =>
                      index <= 1 && (
                        <div
                          key={index}
                          className="flex relative w-1/2 overflow-hidden"
                        >
                          <div className="h-7 w-7 p-1 absolute bg-darkGreen text-white rounded-tl-lg rounded-br-lg left-0">
                            <SioIcon name="suggestionPromt" size={20} />
                          </div>
                          <div
                            onClick={async () => {
                              setIsLoading(true);
                              setSavedQuestionsRecData((prevArray) => {
                                const updatedArray = [
                                  ...prevArray,
                                  {
                                    input: {
                                      content: value,
                                      id: null,
                                    },
                                  },
                                  { response: "Loading..." },
                                ];
                                return updatedArray;
                              });
                              await getConversationsResponse(value);
                              setIsLoading(false);
                            }}
                            className="bg-secondary-200 rounded-lg p-1 ps-9 md:p-3 md:ps-9 hover:cursor-pointer flex items-start grow"
                          >
                            {/* <p className="line-clamp-2">{value}</p> */}
                            <SioTooltipClampedText text={value} lines={2} />
                          </div>
                        </div>
                      )
                  )}
              </div>
            )}
            <div className="mx-2 md:mx-7">
              <div className={`sio-card-shadow flex items-center`}>
                <TextField
                  ref={inputRef}
                  multiline
                  minRows={1}
                  maxRows={8}
                  id="prompt_data"
                  className="w-[100%] focus:outline-none pl-2 text-sm h-[100%]
                      3xl:text-[17px] 3xl:h-[100%]"
                  placeholder="Ask me Anything"
                  onChange={handlePrompt}
                  onKeyDown={handleKeyDown}
                  onPaste={handlePaste}
                  autoComplete="off"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "8px 8px 8px 16px",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
                <button
                  className={`soi-btn soi-btn-fab btn-primary shrink-0 my-2 me-2 self-end ${
                    refreshSavedQuestion.filter(
                      (data) =>
                        data.payload.apiSessionId === updateSessionId.current &&
                        data.status
                    ).length > 0 ||
                    editSavedQuestion.filter(
                      (data) =>
                        data.payload.apiSessionId === updateSessionId.current &&
                        data.status
                    ).length > 0 ||
                    addSavedQuestion.filter(
                      (data) =>
                        data.payload.apiSessionId === updateSessionId.current &&
                        data.status
                    ).length > 0
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  onClick={async () => {
                    if (!prompt || prompt.trim().length === 0) {
                      toast("Please provide valid input");
                      return;
                    }

                    if (
                      refreshSavedQuestion.filter(
                        (data) =>
                          data.payload.apiSessionId ===
                            updateSessionId.current && data.status
                      ).length > 0 ||
                      addSavedQuestion.filter(
                        (data) =>
                          data.payload.apiSessionId ===
                            updateSessionId.current && data.status
                      ).length > 0 ||
                      editSavedQuestion.filter(
                        (data) =>
                          data.payload.apiSessionId ===
                            updateSessionId.current && data.status
                      ).length > 0
                    )
                      return;

                    await fileUploadRequest();
                  }}
                >
                  <SioIcon name="submit" size={16} />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <Dialog
        open={openRemoveModal}
        onClose={() => handleRemoveModalClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="max-w-md flex justify-center items-center text-center p-8 flex-col">
          <SioIcon name="delete" size={80} className="mb-5" />
          <h1 className="text-primary-dark text-xl mb-7">
            Are you sure you want to remove saved question?
          </h1>
          <div className="gap-4 flex">
            <Button
              variant="secondary"
              onClick={() => {
                handleRemoveQuestion();
                setUpdatedQuestionsData(false);
              }}
              size={"sm"}
            >
              Yes, Remove
            </Button>
            <Button
              size={"sm"}
              onClick={() => handleRemoveModalClose()}
              className="text-black"
            >
              Not Now
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default SavedQuestionsContainer;
