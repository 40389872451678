/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Sidebar from "../sidebar/sidebar";
import {
  addMessageToSession,
  bookmarkQuestions,
  getFilesByTopicsWise,
  getFilesList,
  getUserPreferences,
  startConversation,
} from "../../apis/recommendation/recommendation";
import { useLocation } from "react-router-dom";
import { StrivedContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { checkAndRefreshToken } from "../../utils/authUtils";
import { toast } from "react-toastify";
import SioIcon from "../icons/IconLibrary";
import { customFetch } from "../../utils/RefreshTokenInterceptor";
import { devApis } from "../../constants/constant";
import { getUserNames } from "../../utils";
import { getOnboardingStatus } from "../../apis/onboarding";

export const DataContext = createContext();

const Layout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [dataFromSelectRecommendation, setDataFromSelectRecommendation] =
    useState(null);
  const [dataFromSidebar, setDataFromSidebar] = useState(null);
  const [dataFromRecommendation, setDataFromRecommendation] = useState([]);
  const [dataFromBookmark, setDataFromBookmark] = useState([]);
  const [dataFromChildren, setDataFromChildren] = useState(null);
  const [sendUploadedFileData, setSendUploadedFileData] = useState(null);
  const [deleteQuestions, setDeleteQuestions] = useState(false);
  const [selectQuestions, setSelectQuestions] = useState(null);
  const [homePageData, setHomePageData] = useState(null);
  const [highlightField, setHighlightField] = useState(null);
  const [updatedQuestionsData, setUpdatedQuestionsData] = useState(null);
  const [APIData, setAPIData] = useState([]);
  const [dataSource, setDataSource] = useState(null);
  const [filesBasedSubTopics, setFilesBasedSubTopics] = useState(null);
  const [loadingViewFileData, setLoadingViewFileData] = useState(false);
  const [promptData, setPromptData] = useState([]);
  const navigate = useNavigate();
  const [recommendationsByTopics, setRecommendationsByTopics] = useState([]);
  const [filesData, setFilesData] = useState([]);
  const [savedMessagesAPIData, setSavedMessagesAPIData] = useState({});
  const [savedQuestionPrompt, setSavedQuestionPrompt] = useState(null);
  const [savedPreferencesData, setSavedPreferencesData] = useState([]);
  const recId = useRef(null);
  const [refreshAPIData, setRefreshAPIData] = useState([]);

  const location = useLocation();
  const path = location.pathname;

  const [isArchivedDataSources, setIsArchivedDataSources] = useState(() => {
    const value = path.split("/").includes("archived-data-sources")
      ? true
      : false;
    return value;
  });
  const [isFilesType, setIsFilesType] = useState(() => {
    const value = path.split("/").includes("file-types") ? true : false;
    return value;
  });
  const [loadingPromptResponse, setLoadingPromptResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recData, setRecData] = useState(null);
  const updatedSessionId = useRef(null);
  const [currentFileName, setCurrentFileName] = useState(null);
  const [messageId, setMessageId] = useState(null);
  const [aiChildRecommendations, setAiChildRecommendations] = useState(null);
  const [prompt, setPrompt] = useState(null);
  const [savedAiChild, setSavedAiChild] = useState(null);
  const [isLoadingAMAData, setIsLoadingAMAData] = useState(false);
  const [selectedQuestionPrompt, setSelectedQuestionPrompt] = useState(null);
  const [addingNewQuestion, setAddingNewQuestion] = useState(false);
  const [filteredRec, setFilteredRec] = useState([]);
  const [newlyAddedQuestion, setNewlyAddedQuestion] = useState([]);
  const [customPrompt, setCustomPrompt] = useState("");
  const [newQuestionsDataSourceFilter, setNewQuestionsDataSourceFilter] =
    useState([]);
  const [openAddNewQuationDrawer, setAddNewQuationDrawerOpen] = useState(false);
  const [isPromptEdited, setIsPromptEdited] = useState([]);
  const [updatedSaveQuestion, setUpdatedSaveQuestion] = useState("");
  const [AMAAddMessageData, setAMAAddMessageData] = useState([]);
  const [AMARecData, setAMARecData] = useState([]);
  const [AMARefreshQuestion, setAMARefreshQuestion] = useState([]);
  const [AMAEditQuestion, setAMAEditQuestion] = useState([]);
  const [suggestionsRefresh, setSuggestionsRefresh] = useState([]);
  const [refreshSavedQuestion, setRefreshSavedQuestion] = useState([]);
  const [addSavedQuestion, setAddSavedQuestion] = useState([]);
  const [editSavedQuestion, setEditSavedQuestion] = useState([]);
  const [savedQuestionsRecData, setSavedQuestionsRecData] = useState(null);
  const [visitedSavedQuestions, setVisitedSavedQuestions] = useState([]);
  const [totalFileNames, setTotalFileNames] = useState([]);
  const [aiPromptResponse, setAiPromptResponse] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleDataFromSelectRecommendation = (data) => {
    setDataFromSelectRecommendation(data);
  };

  const handleDataFromSidebar = (data) => {
    setDataFromSidebar(data);
  };

  const handleSelectQuestion = (data) => {
    setSelectQuestions(data);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const showSidebar = () => {
    setIsSidebarOpen(true);
  };

  const hideSidebar = () => {
    setIsSidebarOpen(false);
    setDataFromSidebar(null);
  };

  const handleDataFromLanding = (recommendation, bookmark) => {
    setDataFromRecommendation(recommendation);
    setDataFromBookmark(bookmark);
  };

  const handleDeleteQuestion = (data) => {
    setDeleteQuestions(data);
  };

  const handleHomePage = (data) => {
    setHomePageData(data);
  };

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      isSidebarOpen,
      dataFromSidebar,
      dataFromRecommendation,
      dataFromBookmark,
      sendUploadedFileData,
      selectQuestions,
    })
  );

  // const [s3Key, setS3Key] = useState([]);
  const [filesListData, setFilesListData] = useState(null);
  const [isPromptResponseGenerated, setIsPromptResponseGenerated] =
    useState(false);

  const { setSelectSubTopics } = useContext(StrivedContext);

  const [s3Key, setS3Key] = useState(() => {
    const saved = sessionStorage.getItem("s3_keys");
    return saved ? JSON.parse(saved) : [];
  });

  const onboardingData = [
    {
      topic: "Student Information System (SIS)",
      subTopics: [
        "Attendance",
        "Demographics",
        "Class Grades",
        "Discipline",
        "Enrollment",
        "Class Scheduling",
        "Staff Info",
        "School Info",
      ],
    },
    {
      topic: "Assessments",
      subTopics: ["Formative Assessments", "Summative Assessments"],
    },
    {
      topic: "Special Education",
      subTopics: ["IEP Goals"],
    },
    {
      topic: "Behavior Systems",
      subTopics: ["Referrals", "Detentions", "Incentives", "Incident Reports"],
    },
    {
      topic: "Learning Management Systems (LMS)",
      subTopics: ["Assignments", "Quizzes", "Unit Tests"],
    },
    {
      topic: "Progress Monitoring and Edtech Tools",
      subTopics: ["Progress Metrics", "Fidelity", "Skills assessment"],
    },
  ];

  const createAllOptions = (data) => {
    // const result = {};

    // data.forEach((item) => {
    //   const { topic, sub_topic } = item;

    //   if (!topic) return;
    //   if (!result[topic]) {
    //     result[topic] = new Set();
    //   }

    //   result[topic].add(sub_topic);
    // });

    // return Object.entries(result).map(([group, optionsSet]) => ({
    //   group,
    //   options: Array.from(optionsSet),
    // }));
    if (!data) data = [];

    const result = [];

    data.forEach((item) => {
      const topic = item;

      if (!topic) return;
      const modifiedTopic = topic.replace(/\*/g, "");

      if (!modifiedTopic) return;
      if (!result.find((data) => data.group === modifiedTopic)) {
        const getIndex =
          onboardingData[
            onboardingData.findIndex((data) => data.topic === modifiedTopic)
          ];

        const object = {
          group: getIndex.topic,
          options: getIndex.subTopics,
        };

        result.push(object);
      }
    });

    return result;
  };

  const getSubtopicsData = async (topics, sub_topics) => {
    const modifiedSubTopicsObject = createAllOptions(topics);

    const updatedSubTopicsData = modifiedSubTopicsObject.map((item, ind) => {
      return {
        [item.group]: item.options.filter((subTopics) =>
          sub_topics.some((sub_topics) => subTopics.includes(sub_topics))
        ),
      };
    });

    const mappedSubtopicsData = modifiedSubTopicsObject.map((item, ind) => {
      return {
        [item.group]: item.options,
      };
    });

    setSelectSubTopics(updatedSubTopicsData);
    sessionStorage.setItem("subTopics", JSON.stringify(updatedSubTopicsData));
  };

  const getFileData = async (sub_topics) => {
    try {
      setSavedPreferencesData(sub_topics);

      const files = await getFilesList();

      if (files.length > 0) {
        const { data } = await getUserNames();

        const onboardingresp = await getOnboardingStatus({
          org_uuid: data.org_uuid,
          user_uuid: data.user.user_uuid,
        });
        if (!onboardingresp.data.status) {
          // toast.error("Onboarding already completed.");
          navigate("/onboarding");
          return;
        }
      }

      let filteredFileData;
      if (savedPreferencesData.length > 0 || sub_topics.length > 0) {
        filteredFileData = files
          .filter((file) =>
            [".csv", ".json", ".csv.filepart"].some(
              (ext) => file.s3_key.includes(ext) && !file?.archive_status
            )
          )
          .filter((item) => {
            const fileSubtopics = item.sub_topic
              .split(/,\s*/)
              .map((s) => s.trim());

            return fileSubtopics.some((subtopic) =>
              sub_topics.includes(subtopic)
            );
          });
      } else {
        filteredFileData = files.filter((file) =>
          [".csv", ".json", ".csv.filepart"].some(
            (ext) => file.s3_key.includes(ext) && !file?.archive_status
          )
        );
      }
      setTotalFileNames(
        filteredFileData.map((file) => file.original_file_name)
      );
      setFilesData(filteredFileData);
      const filteredS3Keys = filteredFileData
        .filter((item) => !item?.archive_status)
        .map((file) => file.s3_key);

      let allOptions;
      if (filteredFileData.length === 0) {
        filteredFileData = files.filter((file) =>
          [".csv", ".json", ".csv.filepart"].some(
            (ext) => file.s3_key.includes(ext) && !file?.archive_status
          )
        );
        allOptions = createAllOptions(
          filteredFileData.map((item) => item.topic)
        );
      } else {
        allOptions = createAllOptions(
          filteredFileData.map((item) => item.topic)
        );
      }

      if (allOptions.length === 0) {
        sessionStorage.setItem("s3_keys", JSON.stringify([]));
      }

      setFilesBasedSubTopics(allOptions);

      const getOrgSwitchFlag = JSON.parse(
        sessionStorage.getItem("isOrgSwitch")
      );

      if (s3Key.length === 0 || getOrgSwitchFlag) {
        setS3Key(filteredS3Keys);

        sessionStorage.setItem("s3_keys", JSON.stringify(filteredS3Keys));
      }
      setFilesListData(filteredS3Keys);

      setUpdatedQuestionsData(false);
      sessionStorage.setItem("isOrgSwitch", JSON.stringify(false));
    } catch (e) {
      console.error(e);
    }
  };

  const topicsAndSubtopicsUserBased = async () => {
    const { topics, sub_topics } = await getUserPreferences();
    getFileData(sub_topics);
    getSubtopicsData(topics, sub_topics);
  };

  useEffect(() => {
    // getFileData();
    topicsAndSubtopicsUserBased();
  }, []);

  const clearPrompt = () => {
    document.getElementById("prompt_data").value = "";
  };

  const fileUploadRequest = async (
    value,
    childRecType = null,
    isQuestionSaved = null
  ) => {
    setLoadingPromptResponse(true);
    //
    clearPrompt();

    try {
      setIsLoading(true);
      setRecData((prevArray) => {
        const updatedArray = [
          ...prevArray,
          {
            input: {
              content: value,
              id: null,
              isSaved: isQuestionSaved,
            },
          },
          { response: "Loading..." },
        ];
        return updatedArray;
      });

      const token = await checkAndRefreshToken();
      if (!token) navigate("/login");

      const promptValue = value;

      if (!updatedSessionId.current) {
        const data = await startConversation(recId.current, token);
        // setUpdateSessionId(data.session_id);
        updatedSessionId.current = data.session_id;
        setAPIData((prevData) => [
          ...prevData,
          {
            status: true,
            apiCallname: "AddMessage",
            payload: {
              apiPrompt: promptValue,
              apiSessionId: updatedSessionId.current,
              apiRecId: recData[0]?.id,
            },
          },
        ]);
        const data2 = await addMessageToSession(
          data.session_id,
          promptValue,
          // currentFileName ? currentFileName : fileNamesList
          currentFileName
        );
        setMessageId(data2?.message_id);

        if (data2?.recommendation_id === recId.current) {
          setRecData((prevArray) => {
            const updatedArray = [...prevArray];
            updatedArray[updatedArray.length - 1] = {
              response: data2?.answer,
              id: data2?.message_id,
            };
            updatedArray[updatedArray.length - 2] = {
              input: {
                content: promptValue,
                id: data2?.message_id - 1,
                isSaved:
                  (savedAiChild?.qualitative_saved === true &&
                    childRecType === "qualitative_question") ||
                  (savedAiChild?.quantitative_saved === true &&
                    childRecType === "quantitative_question")
                    ? true
                    : false,
              },
            };
            return updatedArray;
          });

          if (
            data2 &&
            data2?.qualitative_question !== null &&
            data2.quantitative_question !== null
          ) {
            const aiRec = [
              data2?.qualitative_question,
              data2?.quantitative_question,
              data2?.qualitative_saved,
              data2?.quantitative_saved,
            ];
            setAiChildRecommendations(aiRec);
          }
        }

        setAPIData((prevData) => {
          const apiData = prevData.filter(
            (item) => item.payload.apiPrompt !== data2?.user_question
          );

          return apiData.filter((data) => data.status);
        });
        // setUpdateSessionId(data.session_id);
        updatedSessionId.current = data.session_id;
      } else {
        setAPIData((prevData) => [
          ...prevData,
          {
            status: true,
            apiCallname: "AddMessage",
            payload: {
              apiPrompt: promptValue,
              apiSessionId: updatedSessionId.current,
              apiRecId: recData[0]?.id,
            },
          },
        ]);
        const data2 = await addMessageToSession(
          updatedSessionId.current,
          promptValue,
          // currentFileName ? currentFileName : fileNamesList
          currentFileName
        );

        setMessageId(data2?.message_id);

        setRecData((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[updatedArray.length - 1] = {
            response: data2?.answer,
            id: data2?.message_id,
          };
          updatedArray[updatedArray.length - 2] = {
            input: {
              content: promptValue,
              id: data2?.message_id - 1,
              isSaved:
                (savedAiChild?.qualitative_saved === true &&
                  childRecType === "qualitative_question") ||
                (savedAiChild?.quantitative_saved === true &&
                  childRecType === "quantitative_question")
                  ? true
                  : false,
            },
          };
          return updatedArray;
        });

        if (
          data2 &&
          data2?.qualitative_question !== null &&
          data2.quantitative_question !== null
        ) {
          const aiRec = [
            data2?.qualitative_question,
            data2?.quantitative_question,
            data2?.qualitative_saved,
            data2?.quantitative_saved,
          ];
          setAiChildRecommendations(aiRec);
        }
        setAPIData((prevData) =>
          prevData.filter(
            (item) => item.payload.apiPrompt !== data2?.user_question
          )
        );
      }
      setLoadingPromptResponse(false);

      setPrompt("");
      setIsLoading(false);
      setIsPromptResponseGenerated(!isPromptResponseGenerated);
    } catch (error) {
      setRecData((prevArray) => prevArray.slice(0, -2));
      toast.error("Error:", error, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      // setAPIData((prevData) => ({
      //   ...prevData,
      //   status: false,
      // }));
      return null;
    }
  };

  const handleAddNewQuestion = async () => {
    if (customPrompt) {
      setAddingNewQuestion(true);
      // const token = await checkAndRefreshToken();
      if (customPrompt.length > 0 && newQuestionsDataSourceFilter.length > 0) {
        setFilteredRec((prev) => [
          {
            content: customPrompt,
            filename: newQuestionsDataSourceFilter.map(
              (data) => data?.original_file_name
            ),
            saved_on: new Date().toUTCString(),
            tempData: true,
            id: 0,
          },
          ...prev,
        ]);
      }
      setSelectedIndex({ id: 0, content: customPrompt });
      setNewlyAddedQuestion((prevQuestion) => [...prevQuestion, customPrompt]);

      const promptResponse = await customFetch(
        `${devApis.PYTHON_BASE_URL}/start_conversation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            recommendation_id: 0,
          }),
        }
      );

      const data = await promptResponse.json();
      const newSessionId = data.session_id;

      const fileArr = newQuestionsDataSourceFilter?.map(
        (obj) => obj.original_file_name
      );
      const extractedNames = filesListData.map((name) =>
        name.split("_").slice(1).join("_")
      );

      const response = await addMessageToSession(
        newSessionId,
        customPrompt,
        fileArr.length > 0 ? fileArr : extractedNames
      );

      await bookmarkQuestions(response?.message_id - 1);
      setVisitedSavedQuestions((prev) =>
        prev.filter((data) => data !== promptResponse)
      );
      setAddingNewQuestion(false);
      setAddNewQuationDrawerOpen(false);
      setUpdatedQuestionsData(true);
      setNewQuestionsDataSourceFilter([]);
      toast.success("Question added successfully", {
        icon: <SioIcon name="check" size={16} color="blue" />,
      });

      setFilteredRec((prev) => prev.filter((item) => !item?.tempData));

      setNewlyAddedQuestion((prevQuestion) => {
        const data = prevQuestion.filter((item) => item !== customPrompt);
        return data;
      });
      return response?.message_id - 1;
    } else {
      toast("Please provide the custom question", {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
    }
  };

  useEffect(() => {
    sessionStorage.setItem("s3_keys", JSON.stringify(s3Key));
  }, [s3Key]);

  if (!filesBasedSubTopics) return;

  return (
    <DataContext.Provider
      value={{
        handleDataFromSelectRecommendation,
        handleDataFromSidebar,
        handleDataFromLanding,
        handleDeleteQuestion,
        dataFromSelectRecommendation,
        dataFromSidebar,
        setDataFromChildren,
        setSendUploadedFileData,
        handleHomePage,
        handleSelectQuestion,
        setHighlightField,
        s3Key,
        setS3Key,
        filesListData,
        updatedQuestionsData,
        setUpdatedQuestionsData,
        APIData,
        setAPIData,
        isArchivedDataSources,
        setIsArchivedDataSources,
        isFilesType,
        setIsFilesType,
        isPromptResponseGenerated,
        setIsPromptResponseGenerated,
        dataSource,
        setDataSource,
        filesBasedSubTopics,
        loadingViewFileData,
        setLoadingViewFileData,
        setFilesBasedSubTopics,
        promptData,
        setPromptData,
        recommendationsByTopics,
        setRecommendationsByTopics,
        filesData,
        savedMessagesAPIData,
        setSavedMessagesAPIData,
        savedQuestionPrompt,
        setSavedQuestionPrompt,
        savedPreferencesData,
        setSavedPreferencesData,
        setFilesData,
        fileUploadRequest,
        isLoading,
        setIsLoading,
        recData,
        setRecData,
        updatedSessionId,
        currentFileName,
        setCurrentFileName,
        messageId,
        setMessageId,
        aiChildRecommendations,
        setAiChildRecommendations,
        prompt,
        setPrompt,
        recId,
        setLoadingPromptResponse,
        loadingPromptResponse,
        savedAiChild,
        setSavedAiChild,
        isLoadingAMAData,
        setIsLoadingAMAData,
        selectedQuestionPrompt,
        setSelectedQuestionPrompt,
        addingNewQuestion,
        setAddingNewQuestion,
        filteredRec,
        setFilteredRec,
        newlyAddedQuestion,
        setNewlyAddedQuestion,
        customPrompt,
        setCustomPrompt,
        newQuestionsDataSourceFilter,
        setNewQuestionsDataSourceFilter,
        openAddNewQuationDrawer,
        setAddNewQuationDrawerOpen,
        handleAddNewQuestion,
        refreshAPIData,
        setRefreshAPIData,
        isPromptEdited,
        setIsPromptEdited,
        updatedSaveQuestion,
        setUpdatedSaveQuestion,
        AMAAddMessageData,
        setAMAAddMessageData,
        AMARecData,
        setAMARecData,
        AMARefreshQuestion,
        setAMARefreshQuestion,
        AMAEditQuestion,
        setAMAEditQuestion,
        suggestionsRefresh,
        setSuggestionsRefresh,
        refreshSavedQuestion,
        setRefreshSavedQuestion,
        addSavedQuestion,
        setAddSavedQuestion,
        editSavedQuestion,
        setEditSavedQuestion,
        savedQuestionsRecData,
        setSavedQuestionsRecData,
        visitedSavedQuestions,
        setVisitedSavedQuestions,
        totalFileNames,
        aiPromptResponse,
        setAiPromptResponse,
        selectedIndex,
        setSelectedIndex,
      }}
    >
      <div className="bg-dark relative flex h-dvh">
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          recId={dataFromSelectRecommendation}
          sendDataToParent={handleDataFromSidebar}
          toggleSidebar={toggleSidebar}
          showSidebar={showSidebar}
          hideSidebar={hideSidebar}
          dataFromChildren={dataFromChildren}
          sendUploadedFileData={sendUploadedFileData}
          deleteQuestions={deleteQuestions}
          selectedQuestions={handleSelectQuestion}
          homePageData={homePageData}
          highlightField={highlightField}
        />

        <div
          className={`flex-1 pb-20 md:pb-3 pt-10 md:pt-3 p-1 md:p-3 ${
            isSidebarOpen ? "lg:pl-0 ml-[0] lg:ml-[19rem]" : ""
          } transition-margin duration-300 h-[100%] w-[1%]`}
        >
          {childrenWithProps}
        </div>
      </div>
    </DataContext.Provider>
  );
};

export default Layout;
